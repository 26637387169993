@import '../../../core/styles/_variables.scss';

.open-order-actions {
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 99;
  &.iphone-x {
    padding-bottom: 20px;
    background-color: $app-white;
  }
  button {
    width: 50%;
    border: 0;
    border-radius: 0;
    color: $app-white;
    font-weight: $font-weight-medium;
    overflow: hidden;
    text-overflow: ellipsis;
    &:first-of-type {
      background-color: $app-green1;
      &:hover,
      &:active {
        color: $app-white;
        background-color: darken($app-green1, 10%);
      }
    }
    &:last-of-type {
      background-color: $app-red;
      &:hover,
      &:active {
        color: $app-white;
        background-color: darken($app-red, 10%);
      }
    }
  }
}
