@import '../../../core/styles/_variables.scss';

.order-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px;
  border-bottom: $default-border;
  > div:first-child {
    width: 5%;
    text-align: center;
    .buy {
      border-radius: $border-radius;
      font-size: 12px;
      background: $app-green-light-color;
      color: $app-green-text-color;
      padding: 1px 4px;
      font-weight: $font-weight-medium;
    }
    .sell {
      border-radius: $border-radius;
      font-size: 12px;
      background: $app-red-light-color;
      color: $app-red;
      padding: 1px 4px;
      font-weight: $font-weight-medium;
    }
  }
  > div {
    width: 11.6%;
  }
  &.open,
  &.closed {
    > div {
      width: 12.7%;
      &:first-of-type {
        width: 7%;
      }
      &:nth-child(2) {
        width: 20%;
      }
      &:last-of-type {
        width: 4.1%;
        .icn {
          color: $app-grey1;
          font-size: 16px;
          &:hover {
            color: $app-blue;
          }
        }
      }
    }
  }
  &.closed {
    > div {
      width: 11.7%;
      &:nth-child(2) {
        width: 15%;
      }
      &:last-of-type {
        width: 11.7%;
      }
    }
  }
  &.title {
    color: $app-grey1;
    font-size: 12px;
    border-bottom: none;
    padding-bottom: 5px;
    padding-top: 30px;
  }
  &:hover:not(.title) {
    cursor: default;
    background: $app-grey3;
    .order-item__actions {
      display: flex;
    }
  }
  &__actions {
    display: none;
    align-items: center;
  }
  &__symbol {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: $font-weight-medium;
    span {
      margin-left: 5px;
      width: calc(100% - 65px);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  &-mobile {
    display: flex;
    flex-direction: column;
    padding: 15px;
    border-bottom: $default-border;
    &__cancel {
      button {
        margin-top: 10px;
        flex: 1;
        color: $app-red;
        &.btn-default:active,
        &.btn-default:hover {
          color: $app-red;
        }
      }
    }
    > div {
      display: flex;
      &:first-of-type {
        justify-content: space-between;
        align-items: center;
      }
      &:not(:first-of-type) {
        margin-top: 10px;
        > div {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 33%;
          > span:first-of-type {
            color: $app-grey1;
          }
        }
      }
    }
    .buy,
    .sell {
      height: 20px;
      border-radius: $border-radius;
      font-size: 12px;
      background: $app-green-light-color;
      color: $app-green-text-color;
      padding: 1px 4px;
      font-weight: $font-weight-medium;
    }
    .buy {
      background: $app-green-light-color;
      color: $app-green-text-color;
    }
    .sell {
      background: $app-red-light-color;
      color: $app-red;
    }
  }
}
