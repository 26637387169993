@import '../../../core/styles/_variables.scss';

.exchange-orders {
  background-color: #fff;
  border: $default-border;
  border-radius: $border-radius;
  &__tabs-container {
    background-color: $app-grey3;
    border-bottom: $default-border;
    height: 40px;
    .tabs-toggle {
      width: 40%;
      @media screen and (max-width: $mobile-resolution-max-width) {
        width: 100%;
      }
      .btn.active {
        border-right: $default-border;
      }
    }
  }
}
