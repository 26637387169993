@import '../../../core/styles/_variables.scss';

.mfa-changed {
  display: flex;
  flex-direction: column;
  &__title {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: $font-weight-medium;
    .icn {
      color: $app-blue;
      margin-right: 10px;
      border: 2px solid lighten($app-blue, 30%);
      border-radius: 50%;
    }
  }
  &__description {
    color: $app-grey1;
    margin: 5px 0;
    font-size: 16px;
    &.mfa-apps {
      > span {
        span {
          color: $app-blue;
          font-weight: $font-weight-medium;
        }
      }
    }
  }
  &__account {
    margin-top: 20px;
    font-size: 16px;
    span {
      font-weight: $font-weight-medium;
    }
  }
  &__codes {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: $default-border;
    &__title {
      font-size: 18px;
      font-weight: $font-weight-medium;
    }
    &__list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      max-width: 500px;
      span {
        margin: 10px 20px 10px 0;
        width: 100px;
        &:first-of-type {
          padding-left: 0;
        }
      }
    }
  }
  &__back {
    margin-bottom: 10px;
    button {
      display: flex;
      align-items: center;
      .icn {
        margin-right: 4px;
      }
    }
  }
  &__copy {
    .btn {
      margin-top: 10px;
      &:first-of-type {
        margin-right: 10px;
      }
    }
  }
  &__note {
    margin-top: 20px;
    font-size: 12px;
    div {
      color: $app-grey1;
    }
  }
}
