@import '../../../core/styles/_mixins.scss';

.forgot-password-wrapper {
  display: flex;
  justify-content: center;
  height: 100%;
  background-color: $app-grey-bright-color;

  .forgot-password-form {
    background: $app-white-color;
    border: $default-border;
    border-radius: $border-radius;
    width: 100%;
    max-width: 480px;
    @media (max-width: $mobile-resolution-max-width) {
      margin-top: 65px;
    }
    &__wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      min-height: 420px;
      &.confirmation {
        align-items: center;
        .forgot-password-form__subtitle {
          text-align: center;
          padding: 0 15px;
        }
      }
    }
    &__title {
      text-align: center;
      margin: 0;
      font-size: 24px;
      font-weight: $font-weight-medium;
      padding: 30px 30px 0px 30px;
      @media (max-width: $mobile-resolution-max-width) {
        padding: 10px 10px 0px 10px;
      }
    }
    &__subtitle {
      text-align: center;
      color: $app-indigo-light-color;
      margin-top: 20px;
      padding: 0px 30px;
      @media (max-width: $mobile-resolution-max-width) {
        padding: 0px 10px;
      }
    }
    &__back-to-login {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: auto;
      padding: 10px 0px;
      border-top: $default-border;
      background-color: hsla(0, 0%, 92.9%, 0.4);
      &__message {
        color: $app-indigo-light-color;
      }
      button {
        margin-left: 5px;
      }
    }
    &__reset {
      display: flex;
      flex-direction: column;
      margin-top: 40px;
      align-items: center;
      button:last-of-type {
        margin-top: 20px;
      }
      &__message {
        color: $app-indigo-light-color;
        margin-bottom: 10px;
        text-align: center;
      }
    }
    &__envelope-img {
      width: 48px;
      height: 48px;
      margin: 40px 0;
    }
    .send-reset-link-btn {
      margin-top: 10px;
      width: 100%;
      .mini-loader {
        position: static;
        height: 18px;
        width: 18px;
        margin: -2px 20px 0;
      }
    }
    .btn-link {
      font-weight: $font-weight-medium;
    }
    form {
      margin-top: 30px;
      width: 100%;
      padding: 0px 30px;
      @media (max-width: $mobile-resolution-max-width) {
        padding: 0px 10px;
      }
    }
  }
}

.forgot-password-modal {
  .modal-content {
    border: none;
  }
  .modal-body {
    padding: 0px;
  }
  .forgot-password-wrapper {
    padding: 0px;
    background: transparent;
  }
  .forgot-password-form__wrapper {
    width: 360px;
    @media (max-width: $mobile-resolution-max-width) {
      width: auto;
    }
  }
  &__close-wrapper {
    i {
      font-size: 22px;
      cursor: pointer;
    }
    position: absolute;
    float: right;
    right: 15px;
    top: 10px;
    @media (max-width: $mobile-resolution-max-width) {
      top: 75px;
    }
  }
}
