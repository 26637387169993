@import '../../../core/styles/_variables.scss';

.user-settings {
  display: flex;
  height: 100%;
  &__navigation {
    display: flex;
    flex-direction: column;
    width: 25%;
    padding: 20px 0 10px 20px;
    border-right: $default-border;
    font-size: 16px;
    &-title {
      margin-bottom: 10px;
      font-size: 24px;
      font-weight: $font-weight-medium;
    }
    &__item {
      display: flex;
      align-items: center;
      color: $app-grey1;
      padding: 10px 0;
      &:hover:not(.active) {
        color: darken($app-grey1, 10%);
      }
      &.active {
        color: $app-blue;
        font-weight: $font-weight-medium;
        border-right: 1px solid $app-blue;
        margin-right: -1px;
      }
      .icn {
        margin-right: 10px;
      }
    }
    &-mobile {
      padding: 20px 20px 0;
      font-weight: $font-weight-medium;
      .navigation-select {
        border: $default-border;
        &__control {
          border: $default-border;
          &--is-focused,
          &--is-focused:hover {
            box-shadow: none;
            border: $default-border;
          }
        }
        &__option {
          &--is-selected {
            a {
              color: $app-white;
            }
          }
        }
      }
      a {
        display: flex;
        align-items: center;
        .icn {
          margin-right: 10px;
        }
      }
    }
  }
  &__content {
    display: flex;
    width: 75%;
  }

  @media (max-width: $mobile-resolution-max-width) {
    flex-direction: column;
    &__content {
      width: 100%;
    }
  }
}
