@import '../../../core/styles/_variables.scss';

.markets {
  &__header {
    padding: 10px;
    &__tabs {
      margin-bottom: 5px;
    }
    &__search {
      width: 100%;
      border: $default-border;
      border-radius: $border-radius;
      padding-left: 10px;
      height: 36px;
      margin-top: 7px;
      background: $app-grey3;
    }
  }
  &__table {
    &__item {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      border-bottom: $default-border;
      font-size: 12px;
      &:last-child {
        border-bottom: none;
      }
      &-name {
        width: 20%;
        font-weight: $font-weight-medium;
      }
      &-price {
        text-align: right;
        width: 52%;
      }
      &-change {
        text-align: right;
        width: 33%;
      }
      &-empty {
        padding: 15px;
        text-align: center;
        color: $app-grey1;
        .mini-loader {
          margin: 0;
          position: initial;
        }
      }
      &.title {
        font-weight: $font-weight-regular;
        color: $app-grey1;
        padding-top: 5px;
      }
      &:hover:not(.title):not(.selected) {
        background: $app-grey3;
        cursor: pointer;
      }
      &.selected {
        border-left: 1px solid $app-blue;
        background-color: lighten($app-blue, 35%);
      }
    }
    &__body {
      height: 227px;
      overflow-y: auto;
    }
  }
}
