@import '../../../core/styles/mixins';

.confirm-email-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  @media screen and (max-width: $mobile-resolution-max-width) {
    align-items: center;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 50%;
    background: #ffffff;
    border: 1px solid #dfe3e9;
    box-shadow: 0 4px 8px 0 rgba(127, 147, 188, 0.1);
    border-radius: 3px;
    @media screen and (max-width: $mobile-resolution-max-width) {
      height: 60%;
      width: 90%;
    }
    > img:not(.mini-loader) {
      margin-bottom: 30px;
    }
    > span {
      width: 80%;
      text-align: center;
      margin-bottom: 30px;
      @media screen and (max-width: $mobile-resolution-max-width) {
        width: 90%;
      }
      &:first-of-type {
        font-size: 30px;
      }
      &:last-of-type {
        margin-bottom: 50px;
      }
    }
    > button {
      width: 60%;
    }
    .mini-loader {
      position: relative;
      margin: 0px;
      width: 60px;
      height: 60px;
    }
    > label {
      font-weight: $font-weight-regular;
      font-size: 16px;
      color: $app-grey1;
      margin-top: 20px;
    }
  }
}
