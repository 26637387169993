@import '../../../../core/styles/variables';

.prompt {
  .modal-content {
    @media (max-width: $mobile-resolution-max-width) {
      height: 100%;
    }
    width: 512px;
    .modal-body {
      height: 100%;
      padding: 20px;
      .prompt {
        &__header {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          &__close-btn {
            text-align: right;
            width: 20px;
            i.icn {
              color: $app-grey1;
              font-weight: $font-weight-black;
            }
          }
        }
        &__title {
          font-size: 24px;
          font-weight: $font-weight-medium;
          line-height: 32px;
          letter-spacing: 0px;
          color: $app-black;
          text-align: center;
          padding: 0 20px;
        }
        &__content {
          padding: 24px 48px;
          text-align: center;
          button {
            margin-top: 48px;
          }
        }
        &__content__message {
          font-size: 16px;
          div {
            margin: 0px;
            color: $app-grey1;
          }
        }
      }
    }
  }
}
