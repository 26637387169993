@import '../../../core/styles/_variables.scss';

.wallet-category {
  margin-bottom: 30px;
  &__title {
    font-size: 18px;
    font-weight: $font-weight-medium;
    padding: 10px 20px 20px;
  }
  &__column-titles {
    padding: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
    padding-left: 20px;
    color: $app-grey1;
    &__text {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: calc(100% - 231px);
    }
    &__item {
      width: 25%;
      &:first-child {
        color: $app-black;
      }
    }
  }
  &__no-data {
    padding: 20px;
    color: $app-grey1;
  }
}
