@import '../../../core/styles/_variables.scss';

.supported-browser {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    height: 48px;
    margin-bottom: 20px;
  }
  &__name {
    margin-bottom: 6px;
  }
  &__version {
    color: $app-grey1;
  }
}
