@import 'variables.scss';

@import url('https://rsms.me/inter/inter-ui.css');

*:hover,
*:focus {
  outline: none !important;
}

html,
body,
#root,
.app-layout {
  height: 100%;
  background: $app-grey-bright-color;
  font-family: $main-font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  &__header {
    min-height: 135px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 0 auto;
    background: $app-white-color;
    border-bottom: $default-border;
  }
  @media (max-width: $mobile-resolution-max-width) {
    .app-content,
    .user-status-box {
      border-radius: 0;
      border-left: none;
      border-right: none;
    }
  }
}

a,
a:hover {
  color: $app-blue;
  text-decoration: none;
  cursor: pointer;
}

.portal-main {
  padding: 15px;
}

.cursor-pointer {
  cursor: pointer;
}
.Loader {
  height: 100%;
  &__background {
    background-color: $app-white-color !important;
  }
  .loader-text {
    font-size: 16px;
    color: $app-grey-dark-color;
    letter-spacing: 0;
    line-height: 32px;
  }
}

.box-wrapper {
  background: $app-indigo-dark-color;
  border: 1px solid #313d4f;
  padding: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 6px;
}

.override-layout {
  .app-layout {
    &__header {
      display: none;
    }
  }
  .app-layout__body {
    display: flex;
    .app-content {
      flex: none;
      margin: auto;
      width: 50vw;
      min-width: 760px;
      min-height: 400px;
      border: none;
      background: transparent;
    }

    @media (max-width: $mobile-resolution-max-width) {
      height: 100%;
      .app-content {
        min-width: inherit;
        width: 100%;
        max-height: 750px;
      }
    }
  }
}

.landing-layout {
  .app-layout__body {
    .app-content {
      width: 100%;
      height: 100%;
      margin: 0px;
      max-width: 100%;
    }
  }
  &--news {
    .app-layout__body,
    .landing__body {
      background: $app-white;
    }
    .landing__body {
      padding: 0;
    }
  }
  &--fees {
    .landing {
      background: $app-white;
    }
  }
  &--token {
    .landing {
      background: $app-white;
    }
  }
  .user-status-box {
    display: none;
  }
}

.custom-layout {
  .app-content {
    background: $app-grey-bright-color;
    border: none;
  }
  @media (min-width: $mobile-resolution-max-width) {
    min-width: 1290px;
  }
}

.modal-open-ios {
  position: fixed;
  width: 100%;
  height: 100%;
}

.modal-dialog-centered {
  display: flex !important;
  align-items: center;
  justify-content: center;
  min-height: calc(100% - (#{$modal-dialog-margin} * 2));
}

// React bootstrap modal is not centered vertically
// Also transition is not that smooth on android
.modal-dialog {
  margin: 5px auto !important;
  @media (max-width: $mobile-resolution-max-width) {
    transform: translate3d(0, 0, 0) !important;
  }
}

button.btn.btn-default {
  &.market-closed,
  &.market-closed:hover,
  &.market-closed:active,
  &.market-closed.active,
  &.market-closed:disabled:hover,
  &.market-closed.disabled:hover {
    background-color: $app-grey-bright-color;
    color: rgba($app-black, 0.8);
    font-weight: $font-weight-regular;
    border: none;
    opacity: 0.6;
  }
}

// Airbnb react dates styling
.CalendarDay {
  &__selected {
    background: $app-blue;
    border: 1px solid lighten($app-blue, 10%);
    &:hover {
      background: $app-blue;
      border: 1px solid lighten($app-blue, 10%);
    }
    &_span {
      background: lighten($app-blue, 25%);
      border: 1px solid lighten($app-blue, 20%);
      &:hover {
        background: lighten($app-blue, 15%);
        border: 1px solid lighten($app-blue, 15%);
      }
    }
  }
  &__hovered_span,
  &__hovered_span:hover {
    background: lighten($app-blue, 25%);
    border: 1px solid lighten($app-blue, 20%);
    color: $app-blue;
  }
}

.DateInput_input {
  &__small {
    font-size: 14px;
  }
  &__focused {
    border-bottom: 2px solid $app-blue;
  }
}

.DateRangePickerInput {
  &_calendarIcon_svg {
    margin-top: -2px;
  }
  &_clearDates_svg__small {
    margin-top: -2px;
  }
  &_calendarIcon {
    margin-left: 0;
  }
}

.banner-blue-bar {
  background: lighten($app-blue, 15%);
}

.rc-slider {
  height: 2px;
  .rc-slider-rail {
    background-color: $app-grey-light-2-color;
    height: 2px;
  }
  .rc-slider-track {
    background-color: $app-blue;
    height: 2px;
  }
  .rc-slider-handle {
    border: solid 1px $app-grey-light-2-color;
  }
}

.tutorial-action {
  z-index: 1001;
  position: relative;
}

input {
  background-clip: padding-box;
  &::placeholder {
    color: $app-grey1;
    opacity: 1; /* Firefox */
  }
  &:-ms-input-placeholder {
    color: $app-grey1;
  }
  &::-ms-input-placeholder {
    color: $app-grey1;
  }
}

.blue-toggle {
  .btn {
    padding: 0 10px;
    border: none;
    background-color: transparent;
    color: $app-blue;
    font-size: 12px;
    font-weight: $font-weight-regular;
    &.btn-default:hover {
      color: $app-blue-active;
      background-color: transparent;
    }
    &.btn-default:active {
      color: $app-blue-active;
      background-color: transparent;
      box-shadow: none;
    }
    &.btn-default.active {
      background-color: $app-blue-active;
      color: $app-white-color;
      font-weight: $font-weight-medium;
      border: 1px solid $app-blue-active;
    }
  }
}

.tabs-toggle {
  height: 40px;
  width: 100%;
  @media (max-width: $mobile-resolution-max-width) {
    border-top: $default-border;
  }
  &.btn-toolbar,
  .btn-group {
    margin-left: 0;
  }
  .btn-group {
    display: flex;
    height: 100%;
    width: 100%;
    .btn {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      border: none;
      border-radius: 0;
      border-bottom: $default-border;
      background-color: $app-grey3;
      color: $app-indigo-light-color;
      font-size: 12px;
      margin-left: 0px;
    }
    .btn.active {
      box-shadow: none;
      border-bottom: none;
      background-color: $app-white-color;
      border-right: $default-border;
      border-left: $default-border;
    }
    .btn.active:first-child {
      border-left: none;
    }
  }
}

.animate-color-red {
  animation-name: changeToRed;
  animation-duration: 1s;
}

.animate-color-green {
  animation-name: changeToGreen;
  animation-duration: 1s;
}

@keyframes changeToRed {
  0% {
    color: $app-black;
  }
  50% {
    color: $app-red-color;
  }
  100% {
    color: $app-black;
  }
}

@keyframes changeToGreen {
  0% {
    color: $app-black;
  }
  50% {
    color: $app-green-color;
  }
  100% {
    color: $app-black;
  }
}
