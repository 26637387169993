.mini-loader {
  position: absolute;
  height: 36px;
  width: 36px;
  margin: -4px 0 0 -45px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
