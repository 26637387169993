@import '../../../../core/styles/_mixins.scss';

.default-input {
  text-align: left;
  label {
    color: $app-indigo-dark-color;
    font-weight: $font-weight-regular;
    line-height: 20px;
    letter-spacing: -0.26px;
  }
  .input-and-error {
    height: 55px;
    position: relative;
    input {
      height: 36px;
      width: 100%;
      padding: 5px 5px 5px 10px;
      border-radius: $border-radius;
      border: $default-border;
      background-color: $app-white-color;
      color: $app-indigo-dark-color;
      line-height: 20px;
      &:focus {
        outline: none;
      }
    }
    .error {
      color: $app-red-color;
      font-size: 12px;
    }
    .extended-part {
      position: absolute;
      right: 7px;
      top: 6px;
      img {
        position: relative;
        margin-left: -30px;
        margin-top: 4px;
        width: 20px;
        height: 20px;
      }
    }
    @include error-input-styles;
    .error {
      @include error-label-styles;
    }
  }
  &.password-input {
    input {
      padding-right: 24px;
    }
    i.glyphicon {
      vertical-align: middle;
      cursor: pointer;
      color: #999999;
    }
  }
}
