@import '../../../core/styles/_variables.scss';

.verify-email {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    p {
      color: $app-grey1;
      margin-bottom: 0px;
    }
    &__icon {
      background-image: url('./images/verify-email.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      width: 50px;
      height: 50px;
      margin-bottom: 40px;
    }
    &__title {
      font-size: 24px;
    }
    > div:nth-child(3) {
      margin: 10px 0px 20px 0px;
    }
  }
  &__actions {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: $app-grey1;
    &__resend {
      margin-left: 5px;
    }
    &__icon {
      background-image: url('./images/success.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }
  &__success {
    button {
      margin-top: 20px;
    }
  }
}
