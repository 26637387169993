@import '../../../core/styles/_variables.scss';

.blocked-popup-modal {
  .modal-dialog {
    width: 310px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
  }
  .description {
    > div:last-child {
      margin-top: 10px;
    }
  }
  .button {
    margin-top: 20px;
    width: 100%;
    padding: 11px 0px;
    border: none;
    background: $app-blue;
    color: #fff;
    border-radius: $border-radius;
    font-size: 14px;
  }
}
