@import '../../../core/styles/_mixins.scss';

.reset-password-wrapper {
  display: flex;
  justify-content: center;
  background-color: $app-grey-bright-color;
  padding: 10px 50px;
  height: 400px;

  .reset-password-form {
    background: $app-white-color;
    border: $default-border;
    border-radius: $border-radius;
    margin-top: 65px;
    &__wrapper {
      display: flex;
      flex-direction: column;
      min-width: 480px;
      height: 100%;
      min-height: 320px;
      padding: 30px;
    }
    &__title {
      text-align: center;
      margin: 0;
      font-size: 24px;
      font-weight: $font-weight-medium;
    }
    &__subtitle {
      color: $app-indigo-light-color;
      margin-top: 20px;
    }
    &__reset {
      display: flex;
      flex-direction: column;
      margin-top: auto;
      align-items: center;
      &__message {
        color: $app-indigo-light-color;
        margin-bottom: 10px;
      }
    }
    &__envelope-img {
      width: 48px;
      height: 48px;
      margin: 40px 0;
    }
    .default-input {
      .input-and-error {
        height: 60px;
      }
    }
    button {
      margin-top: 20px;
      width: 100%;
      .mini-loader {
        position: static;
        height: 18px;
        width: 18px;
        margin: -2px 16px 0;
      }
    }
    form {
      margin-top: 50px;
      width: 100%;
    }
  }
}

@media (max-width: $mobile-resolution-max-width) {
  .override-layout {
    .reset-password-wrapper {
      width: 100%;
      height: 100%;
      flex: 1;
      margin-top: 0;
      justify-content: center;
      padding: 0;
      .reset-password-form {
        display: flex;
        flex: 1;
        flex-direction: column;
        max-width: 500px;
        min-height: 580px;
        border: 0;
        border-radius: 0;
        &__wrapper {
          flex: 1;
          padding: 30px 20px;
          min-width: 300px;
        }
        &__body {
          flex: 1;
        }
        &__try-app {
          > div {
            margin-left: 0;
          }
        }
      }
    }
  }
}
