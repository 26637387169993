@import '../../../../core/styles/variables.scss';

.nav-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
  width: 100%;
  border-bottom: 1px solid #e6e6e6;
  background: $app-indigo-dark-color;
  position: relative;
  > div {
    width: 100%;
    max-width: $desktop-resolution-max-width;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .layout__brand-image {
    flex: 0 0 20%;
    height: 100%;
  }
  .navbar-brand {
    height: auto;
    width: auto;

    &-logo {
      background-size: contain;
      background-position: 0px 0px;
      background-repeat: no-repeat;
      width: 239px;
      height: 32px;
      &.full {
        background-image: url('../../../registration/styles/images/logo-white.png');
      }
      &.short {
        background-image: url('../../../registration/styles/images/logo-short.png');
        width: 40px;
        height: 40px;
        display: none;
      }
      &.show-logo {
        @media screen and (max-width: 360px) {
          display: flex;
        }
      }
      &.hide-logo {
        @media screen and (max-width: 360px) {
          display: none;
        }
      }
      &:hover {
        cursor: pointer;
      }
    }
    @media (min-width: 1300px) {
      padding-left: 0px;
    }
  }
  &__profile {
    width: 180px;
    display: flex;
    justify-content: flex-end;
    @media (max-width: $mobile-resolution-max-width) {
      width: 150px;
      display: flex;
      align-items: center;
    }
    .search,
    .notification-button {
      font-size: 20px;
      margin-right: 15px;
      color: $app-grey2;
      &:hover {
        cursor: pointer;
      }
    }
    &-buttons {
      button {
        padding: 7px 20px;
        border: $default-border;
        border-radius: $border-radius;
        &:first-child {
          margin-right: 10px;
          background: $app-grey2;
        }
        &:last-child {
          background: transparent;
          color: #fff;
          margin-right: 15px;
        }
      }
    }
  }
}
