/* --------------------------------

naga-icons Web Font
Generated using nucleoapp.com

-------------------------------- */
@import 'font-variables';
@import 'font-mixins';

@font-face {
  font-family: 'naga-icons';
  src: url('#{$icn-font-path}/naga-icons.eot');
  src: url('#{$icn-font-path}/naga-icons.eot') format('embedded-opentype'), url('#{$icn-font-path}/naga-icons.woff2') format('woff2'), url('#{$icn-font-path}/naga-icons.woff') format('woff'),
    url('#{$icn-font-path}/naga-icons.ttf') format('truetype'), url('#{$icn-font-path}/naga-icons.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

/*------------------------
	base class definition
-------------------------*/

.icn {
  display: inline-block;
  font: normal normal normal 1em/1 'naga-icons';
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*------------------------
  change icon size
-------------------------*/

/* relative units */
.icn-sm {
  font-size: 0.8em;
}
.icn-lg {
  font-size: 1.2em;
}
/* absolute units */
.icn-16 {
  font-size: 16px;
}
.icn-32 {
  font-size: 32px;
}

/*----------------------------------
  add a square/circle background
-----------------------------------*/

.icn-bg-square,
.icn-bg-circle {
  padding: 0.35em;
  background-color: $icn-background-color;
}

.icn-bg-circle {
  border-radius: 50%;
}

/*------------------------------------
  use icons as list item markers
-------------------------------------*/

.icn-ul {
  padding-left: 0;
  list-style-type: none;

  > li {
    display: flex;
    align-items: flex-start;
    line-height: 1.4;
  }

  > li > .icn {
    margin-right: $icn-li-margin-right;
    line-height: inherit;
  }
}

/*------------------------
  spinning icons
-------------------------*/

.icn-is-spinning {
  -webkit-animation: icn-spin 2s infinite linear;
  -moz-animation: icn-spin 2s infinite linear;
  animation: icn-spin 2s infinite linear;
}

@-webkit-keyframes icn-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes icn-spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@keyframes icn-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*------------------------
  rotated/flipped icons
-------------------------*/

.icn-rotate-90 {
  @include icn-rotate(90deg, 1);
}

.icn-rotate-180 {
  @include icn-rotate(180deg, 2);
}

.icn-rotate-270 {
  @include icn-rotate(270deg, 3);
}

.icn-flip-y {
  @include icn-flip(-1, 1, 0);
}
.icn-flip-x {
  @include icn-flip(1, -1, 2);
}

@import 'icons.scss';
