@import '../../../core/styles/_variables.scss';
@import '../../../core/styles/_variables.scss';

.trading-view-chart {
  height: 298px;
  width: calc(100% - 1px);
  > div {
    height: 100%;
  }
  @media (max-width: $mobile-resolution-max-width) {
    width: 100%;
  }
}
