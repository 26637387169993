@import '../../../core/styles/variables.scss';

.phone-verification-warning-modal {
  .modal-dialog {
    margin: 0px auto;
  }
  .modal-body {
    padding-top: 0;
  }
  .modal-content {
    max-width: 420px;
    margin: 0px auto;
  }
  .modal-header {
    border-bottom: 0;
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__item {
      display: flex;
      margin-bottom: 15px;
      &__smartphone {
        margin-top: -25px;
        img {
          height: 70px;
        }
      }
      &__title {
        font-size: 16px;
        font-weight: 500;
      }
      &__content {
        color: #989ca3;
        padding: 0 30px;
        font-size: 12px;
        text-align: center;
      }
      .verify-phone {
        &__arrow-right {
          height: 10px;
          margin-left: 5px;
          margin-bottom: 2px;
        }
      }
      .mini-loader {
        position: static;
        height: 20px;
        width: 20px;
        margin: -2px 20px 0;
      }
    }
  }

  @media screen and (max-width: $mobile-resolution-max-width) {
    .modal-dialog {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }
}
