.symbol {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 60px;
  height: 35px;

  &.mini {
    width: 36px;
    height: 36px;
  }
  &.small {
    width: 40px;
    height: 40px;
  }
  &.medium {
    width: 56px;
    height: 56px;
  }
  &.large {
    width: 80px;
    height: 80px;
  }
}
