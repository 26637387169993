@import '../../../core/styles/_variables.scss';

.market-depth-chart {
  height: 280px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  .mini-loader {
    position: relative;
    margin: 0px;
    width: 40px;
    height: 40px;
  }
}
