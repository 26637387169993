/*------------------------
	icons
-------------------------*/
@function unicode($str) {
  @return unquote('"\\#{$str}"');
}

$icn-simple-remove-big-thin: unicode(ea03);
$icn-pencil-thin: unicode(ea04);
$icn-image-add-thin: unicode(ea05);
$icn-check-small-thin: unicode(ea06);
$icn-alert: unicode(ea07);
$icn-stock: unicode(ea08);
$icn-chat-v2: unicode(ea09);
$icn-circle-remove: unicode(ea0a);
$icn-small-add-thin: unicode(ea0b);
$icn-small-delete-thin: unicode(ea0c);
$icn-star: unicode(ea0f);
$icn-chart-bar: unicode(ea10);
$icn-android: unicode(ea13);
$icn-a-add-thin-thin: unicode(ea14);
$icn-a-check-thin: unicode(ea15);
$icn-alert-info-thin: unicode(ea16);
$icn-alert-question-thin: unicode(ea17);
$icn-badge-thin: unicode(ea18);
$icn-bell: unicode(ea19);
$icn-bolt-thin: unicode(ea1a);
$icn-button-power-thin: unicode(ea1b);
$icn-calendar: unicode(ea1c);
$icn-card-add-thin: unicode(ea1d);
$icn-circle-check: unicode(ea1e);
$icn-circle-add-small: unicode(ea1f);
$icn-circle-add: unicode(ea20);
$icn-circle-delete: unicode(ea21);
$icn-credit-card-in-thin: unicode(ea22);
$icn-currency-exchange-thin: unicode(ea23);
$icn-email-big: unicode(ea24);
$icn-filter: unicode(ea25);
$icn-language-thin: unicode(ea26);
$icn-menu-dots: unicode(ea27);
$icn-minimal-left-big-thin: unicode(ea28);
$icn-opening-times-thin: unicode(ea29);
$icn-box-remove: unicode(ea2b);
$icn-settings: unicode(ea2c);
$icn-slide-right: unicode(ea2d);
$icn-strong-up-thin: unicode(ea2e);
$icn-tail-down-thin: unicode(ea2f);
$icn-tail-left-thin: unicode(ea30);
$icn-tail-right-thin: unicode(ea31);
$icn-tail-triangle-down: unicode(ea32);
$icn-tail-triangle-left: unicode(ea33);
$icn-tail-triangle-right: unicode(ea34);
$icn-tail-triangle-up: unicode(ea35);
$icn-tail-up-thin: unicode(ea36);
$icn-time-thin: unicode(ea37);
$icn-trash: unicode(ea38);
$icn-trend-down: unicode(ea39);
$icn-trend-up: unicode(ea3a);
$icn-wallet-thin: unicode(ea3b);
$icn-triangle-right: unicode(ea3c);
$icn-zoom: unicode(ea3d);
$icn-auto-copied: unicode(ea3f);
$icn-ungroup-big-thin: unicode(ea40);
$icn-currency-exchange-big-thin: unicode(ea41);
$icn-explore-user-thin: unicode(ea42);
$icn-round-dollar: unicode(ea43);
$icn-bitcoin: unicode(ea44);
$icn-cash-register-thin: unicode(ea45);
$icn-auto-copy: unicode(ea48);
$icn-steps-thin: unicode(ea49);
$icn-add-user-thin: unicode(ea4a);
$icn-heart: unicode(ea4b);
$icn-heart-thin: unicode(ea4c);
$icn-chat-thin: unicode(ea4d);
$icn-chat: unicode(ea4e);
$icn-ungroup: unicode(ea51);
$icn-a-add: unicode(ea52);
$icn-eye-thin: unicode(ea53);
$icn-at-sign-thin: unicode(ea54);
$icn-coins: unicode(ea55);
$icn-todo-thin: unicode(ea57);
$icn-rejected-thin: unicode(ea58);
$icn-send: unicode(ea59);
$icn-alert-circle-big-thin: unicode(ea5c);
$icn-alert-circle-small-thin: unicode(ea5d);
$icn-movie-thin: unicode(ea5f);
$icn-camera-compact-thin: unicode(ea60);
$icn-smile-thin: unicode(ea61);
$icn-pin-thin: unicode(ea62);
$icn-grid-thin: unicode(ea63);
$icn-bullet-list-thin: unicode(ea64);
$icn-alert-circle-exc: unicode(ea65);
$icn-lock: unicode(ea66);
$icn-drag-up: unicode(ea67);
$icn-wand-thin: unicode(ea68);
$icn-price-alert-active: unicode(ea69);
$icn-price-alert: unicode(ea6a);
$icn-phone: unicode(ea6b);
$icn-email-small: unicode(ea6c);
$icn-naga-wallet-thin: unicode(ea6d);
$icn-copy-wallet-thin: unicode(ea6e);
$icn-webinars-thin: unicode(ea6f);
$icn-check-big-thin: unicode(ea70);
$icn-tablet-reader: unicode(ea71);
$icn-hamburger-menu: unicode(ea72);
$icn-small-up: unicode(ea73);
$icn-small-down: unicode(ea74);
$icn-small-left: unicode(ea75);
$icn-small-right: unicode(ea76);
$icn-small-up-thin: unicode(ea77);
$icn-small-down-thin: unicode(ea78);
$icn-small-left-thin: unicode(ea79);
$icn-small-right-thin: unicode(ea7a);
$icn-external-link: unicode(ea7c);
$icn-alert-circle-i: unicode(ea7d);
$icn-folder-remove: unicode(ea7e);
$icn-share-bold: unicode(ea7f);
$icn-curved-next-thin: unicode(ea80);
$icn-share-thin: unicode(ea81);
$icn-security-settings: unicode(ea82);
$icn-personal-settings: unicode(ea83);
$icn-payment-setttings: unicode(ea84);
$icn-link-broken: unicode(ea85);
$icn-money-bag-thin: unicode(ea86);
$icn-strong-down-thin: unicode(ea87);
$icn-block-bottom-right-thin: unicode(ea88);
$icn-block-top-right-thin: unicode(ea89);
$icn-naga-card-thin: unicode(ea8a);
$icn-chart-view-trades: unicode(ea8c);
$icn-info-v2: unicode(ea8d);
$icn-copy-link-2-thin: unicode(ea8e);

.icn-simple-remove-big-thin::before {
  content: $icn-simple-remove-big-thin;
}

.icn-pencil-thin::before {
  content: $icn-pencil-thin;
}

.icn-image-add-thin::before {
  content: $icn-image-add-thin;
}

.icn-check-small-thin::before {
  content: $icn-check-small-thin;
}

.icn-alert::before {
  content: $icn-alert;
}

.icn-stock::before {
  content: $icn-stock;
}

.icn-chat-v2::before {
  content: $icn-chat-v2;
}

.icn-circle-remove::before {
  content: $icn-circle-remove;
}

.icn-small-add-thin::before {
  content: $icn-small-add-thin;
}

.icn-small-delete-thin::before {
  content: $icn-small-delete-thin;
}

.icn-star::before {
  content: $icn-star;
}

.icn-chart-bar::before {
  content: $icn-chart-bar;
}

.icn-android::before {
  content: $icn-android;
}

.icn-a-add-thin-thin::before {
  content: $icn-a-add-thin-thin;
}

.icn-a-check-thin::before {
  content: $icn-a-check-thin;
}

.icn-alert-info-thin::before {
  content: $icn-alert-info-thin;
}

.icn-alert-question-thin::before {
  content: $icn-alert-question-thin;
}

.icn-badge-thin::before {
  content: $icn-badge-thin;
}

.icn-bell::before {
  content: $icn-bell;
}

.icn-bolt-thin::before {
  content: $icn-bolt-thin;
}

.icn-button-power-thin::before {
  content: $icn-button-power-thin;
}

.icn-calendar::before {
  content: $icn-calendar;
}

.icn-card-add-thin::before {
  content: $icn-card-add-thin;
}

.icn-circle-check::before {
  content: $icn-circle-check;
}

.icn-circle-add-small::before {
  content: $icn-circle-add-small;
}

.icn-circle-add::before {
  content: $icn-circle-add;
}

.icn-circle-delete::before {
  content: $icn-circle-delete;
}

.icn-credit-card-in-thin::before {
  content: $icn-credit-card-in-thin;
}

.icn-currency-exchange-thin::before {
  content: $icn-currency-exchange-thin;
}

.icn-email-big::before {
  content: $icn-email-big;
}

.icn-filter::before {
  content: $icn-filter;
}

.icn-language-thin::before {
  content: $icn-language-thin;
}

.icn-menu-dots::before {
  content: $icn-menu-dots;
}

.icn-minimal-left-big-thin::before {
  content: $icn-minimal-left-big-thin;
}

.icn-opening-times-thin::before {
  content: $icn-opening-times-thin;
}

.icn-box-remove::before {
  content: $icn-box-remove;
}

.icn-settings::before {
  content: $icn-settings;
}

.icn-slide-right::before {
  content: $icn-slide-right;
}

.icn-strong-up-thin::before {
  content: $icn-strong-up-thin;
}

.icn-tail-down-thin::before {
  content: $icn-tail-down-thin;
}

.icn-tail-left-thin::before {
  content: $icn-tail-left-thin;
}

.icn-tail-right-thin::before {
  content: $icn-tail-right-thin;
}

.icn-tail-triangle-down::before {
  content: $icn-tail-triangle-down;
}

.icn-tail-triangle-left::before {
  content: $icn-tail-triangle-left;
}

.icn-tail-triangle-right::before {
  content: $icn-tail-triangle-right;
}

.icn-tail-triangle-up::before {
  content: $icn-tail-triangle-up;
}

.icn-tail-up-thin::before {
  content: $icn-tail-up-thin;
}

.icn-time-thin::before {
  content: $icn-time-thin;
}

.icn-trash::before {
  content: $icn-trash;
}

.icn-trend-down::before {
  content: $icn-trend-down;
}

.icn-trend-up::before {
  content: $icn-trend-up;
}

.icn-wallet-thin::before {
  content: $icn-wallet-thin;
}

.icn-triangle-right::before {
  content: $icn-triangle-right;
}

.icn-zoom::before {
  content: $icn-zoom;
}

.icn-auto-copied::before {
  content: $icn-auto-copied;
}

.icn-ungroup-big-thin::before {
  content: $icn-ungroup-big-thin;
}

.icn-currency-exchange-big-thin::before {
  content: $icn-currency-exchange-big-thin;
}

.icn-explore-user-thin::before {
  content: $icn-explore-user-thin;
}

.icn-round-dollar::before {
  content: $icn-round-dollar;
}

.icn-bitcoin::before {
  content: $icn-bitcoin;
}

.icn-cash-register-thin::before {
  content: $icn-cash-register-thin;
}

.icn-auto-copy::before {
  content: $icn-auto-copy;
}

.icn-steps-thin::before {
  content: $icn-steps-thin;
}

.icn-add-user-thin::before {
  content: $icn-add-user-thin;
}

.icn-heart::before {
  content: $icn-heart;
}

.icn-heart-thin::before {
  content: $icn-heart-thin;
}

.icn-chat-thin::before {
  content: $icn-chat-thin;
}

.icn-chat::before {
  content: $icn-chat;
}

.icn-ungroup::before {
  content: $icn-ungroup;
}

.icn-a-add::before {
  content: $icn-a-add;
}

.icn-eye-thin::before {
  content: $icn-eye-thin;
}

.icn-at-sign-thin::before {
  content: $icn-at-sign-thin;
}

.icn-coins::before {
  content: $icn-coins;
}

.icn-todo-thin::before {
  content: $icn-todo-thin;
}

.icn-rejected-thin::before {
  content: $icn-rejected-thin;
}

.icn-send::before {
  content: $icn-send;
}

.icn-alert-circle-big-thin::before {
  content: $icn-alert-circle-big-thin;
}

.icn-alert-circle-small-thin::before {
  content: $icn-alert-circle-small-thin;
}

.icn-movie-thin::before {
  content: $icn-movie-thin;
}

.icn-camera-compact-thin::before {
  content: $icn-camera-compact-thin;
}

.icn-smile-thin::before {
  content: $icn-smile-thin;
}

.icn-pin-thin::before {
  content: $icn-pin-thin;
}

.icn-grid-thin::before {
  content: $icn-grid-thin;
}

.icn-bullet-list-thin::before {
  content: $icn-bullet-list-thin;
}

.icn-alert-circle-exc::before {
  content: $icn-alert-circle-exc;
}

.icn-lock::before {
  content: $icn-lock;
}

.icn-drag-up::before {
  content: $icn-drag-up;
}

.icn-wand-thin::before {
  content: $icn-wand-thin;
}

.icn-price-alert-active::before {
  content: $icn-price-alert-active;
}

.icn-price-alert::before {
  content: $icn-price-alert;
}

.icn-phone::before {
  content: $icn-phone;
}

.icn-email-small::before {
  content: $icn-email-small;
}

.icn-naga-wallet-thin::before {
  content: $icn-naga-wallet-thin;
}

.icn-copy-wallet-thin::before {
  content: $icn-copy-wallet-thin;
}

.icn-webinars-thin::before {
  content: $icn-webinars-thin;
}

.icn-check-big-thin::before {
  content: $icn-check-big-thin;
}

.icn-tablet-reader::before {
  content: $icn-tablet-reader;
}

.icn-hamburger-menu::before {
  content: $icn-hamburger-menu;
}

.icn-small-up::before {
  content: $icn-small-up;
}

.icn-small-down::before {
  content: $icn-small-down;
}

.icn-small-left::before {
  content: $icn-small-left;
}

.icn-small-right::before {
  content: $icn-small-right;
}

.icn-small-up-thin::before {
  content: $icn-small-up-thin;
}

.icn-small-down-thin::before {
  content: $icn-small-down-thin;
}

.icn-small-left-thin::before {
  content: $icn-small-left-thin;
}

.icn-small-right-thin::before {
  content: $icn-small-right-thin;
}

.icn-external-link::before {
  content: $icn-external-link;
}

.icn-alert-circle-i::before {
  content: $icn-alert-circle-i;
}

.icn-folder-remove::before {
  content: $icn-folder-remove;
}

.icn-share-bold::before {
  content: $icn-share-bold;
}

.icn-curved-next-thin::before {
  content: $icn-curved-next-thin;
}

.icn-share-thin::before {
  content: $icn-share-thin;
}

.icn-security-settings::before {
  content: $icn-security-settings;
}

.icn-personal-settings::before {
  content: $icn-personal-settings;
}

.icn-payment-setttings::before {
  content: $icn-payment-setttings;
}

.icn-link-broken::before {
  content: $icn-link-broken;
}

.icn-money-bag-thin::before {
  content: $icn-money-bag-thin;
}

.icn-strong-down-thin::before {
  content: $icn-strong-down-thin;
}

.icn-block-bottom-right-thin::before {
  content: $icn-block-bottom-right-thin;
}

.icn-block-top-right-thin::before {
  content: $icn-block-top-right-thin;
}

.icn-naga-card-thin::before {
  content: $icn-naga-card-thin;
}

.icn-chart-view-trades::before {
  content: $icn-chart-view-trades;
}

.icn-info-v2::before {
  content: $icn-info-v2;
}

.icn-copy-link-2-thin::before {
  content: $icn-copy-link-2-thin;
}
