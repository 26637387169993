@import '../../../core/styles/variables.scss';

.message-modal {
  @media (min-width: $mobile-resolution-max-width), (min-height: 500px) and (orientation: portrait) {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    &--bottom {
      justify-content: flex-end;
    }
  }
  .modal-dialog {
    @media (min-width: 400px) {
      max-width: 350px;
      margin: 20px auto;
    }
    &.modal-dialog-centered {
      // Override parent class as we don't need modal centered
      display: block !important;
      min-height: auto;
    }
  }
  &__body {
    &.modal-body {
      padding-left: 0;
      padding-right: 0;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    &--hmb {
      :nth-child(1) {
        order: 1;
      }
      :nth-child(2) {
        order: 3;
      }
      :nth-child(3) {
        order: 2;
      }
    }
    &--mhb {
      .message-modal {
        &__media {
          margin-top: -15px !important;
        }
        &__media-image,
        &__media-video,
        &__media-youtube-video {
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }
      }
      :nth-child(1) {
        order: 2;
      }
      :nth-child(2) {
        order: 3;
      }
      :nth-child(3) {
        order: 1;
      }
    }
  }
  &__title {
    font-size: 16px;
    color: $app-indigo-dark-color;
    padding: 0 15px;
  }
  &__description {
    font-size: 14px;
    color: $app-indigo-light-color;
    margin: 5px 0;
    padding: 0 15px;
  }
  &__media {
    margin: 5px 0;
  }
  &__media-image {
    height: 150px;
    background-size: cover;
    background-position: center;
  }
  &__media-video {
    height: 200px;
    width: 100%;
    background-color: $app-black-color;
  }
  &__media-youtube-video {
    background-color: $app-black-color;
  }
  &__actions {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px 0;
    &--single {
      justify-content: center;
    }
    &--accept {
      background: $app-blue;
      height: 36px;
      min-width: 70px;
      color: $app-white-color;
    }
    &--reject {
      height: 36px;
      color: $app-indigo-dark-color;
      min-width: 70px;
    }
    button {
      width: 48%;
      font-weight: $font-weight-medium;
      font-size: 14px;
      &:hover,
      &:focus,
      &:active {
        opacity: 0.9;
        outline: none;
      }
    }
  }
}
