@import '../../../core/styles/_variables.scss';

.symbol-info {
  display: flex;
  align-items: center;
  min-height: 72px;
  padding: 10px;
  > div:first-child {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
  }
  &__symbol {
    display: flex;
    margin-left: 10px;
    margin-right: 20px;
    padding: 5px 0;
    &__name {
      font-size: 20px;
      line-height: 24px;
      font-weight: $font-weight-bold;
      .icn {
        position: relative;
        font-weight: $font-weight-bold;
        top: 2px;
        right: 3px;
      }
    }
    &__pair {
      font-size: 12px;
      line-height: 16px;
      color: $app-grey1;
      font-weight: $font-weight-medium;
    }
    > div:not(:first-child) {
      display: flex;
      flex-direction: column;
      margin-left: 15px;
    }
  }
  &__stats {
    display: flex;
    > div {
      display: flex;
      flex-direction: column;
      margin: 0 25px;
      padding: 5px 0;
      font-size: 12px;
      &:first-of-type {
        margin-left: 10px;
      }
      > div:last-child {
        font-weight: $font-weight-medium;
      }
    }
  }
  &__label {
    color: $app-grey1;
  }
  &__day-change {
    display: flex;
    > span:first-child {
      margin-right: 5px;
    }
  }
  &__details {
    > div {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }
  &__naga-guard {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    padding: 5px 0;
    order: 2;
    &__image {
      cursor: pointer;
      width: 60px;
      height: 34px;
      margin-right: 0;
      background-image: url('images/naga-guard-mobile.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      align-self: center;
    }
  }
  &.loading,
  &.no-data {
    justify-content: center;
  }
  &.no-data {
    color: $app-grey1;
  }
  &.crypto-quote {
    .symbol-info__stats {
      > div {
        margin: 0 12px;
      }
    }
  }
  &.mobile {
    flex-direction: column;
    align-items: initial;
    > div:not(:first-child) {
      flex-direction: row;
      margin: 0 5px;
    }
    .symbol-info__symbol {
      margin: 0 0 10px 0;
      padding: 5px;
    }
    .symbol-info__details {
      font-size: 12px;
      display: flex;
      &__high-low {
        max-height: 40px;
        > div {
          display: flex;
          .symbol-info__label {
            margin-right: 10px;
          }
        }
      }
    }
    .symbol-info__naga-guard {
      &__image {
        align-self: flex-end;
      }
    }
  }
}
.mini-loader {
  margin: 0;
}
