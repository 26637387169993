@import '../../../core/styles/_variables.scss';

.header-bar {
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 65px;
  padding: 0px 20px;
  background-color: $app-black;
  @media screen and (max-width: $mobile-resolution-max-width) {
    padding: 0px 10px;
  }
  img {
    height: 35px;
  }
  &__languages {
    &.btn,
    &.dropdown-toggle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: auto;
      background-color: transparent;
      color: $app-white-color;
      border: none;
      padding: 0px;
      &:focus,
      &:active,
      &:hover {
        background-color: transparent;
        color: $app-white-color;
      }
      img {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        margin-right: 5px;
      }
      .caret {
        margin-left: 5px;
      }
    }
  }
  .btn-default:active,
  .btn-default.active,
  .open > .dropdown-toggle.btn-default,
  .btn-default:active:hover,
  .btn-default.active:hover,
  .open > .dropdown-toggle.btn-default:hover,
  .btn-default:active:focus,
  .btn-default.active:focus,
  .open > .dropdown-toggle.btn-default:focus,
  .btn-default:active.focus,
  .btn-default.active.focus,
  .open > .dropdown-toggle.btn-default.focus {
    background-color: transparent;
    color: $app-white-color;
    box-shadow: none;
  }

  .dropdown {
    > ul {
      border: $default-border;
      border-radius: $border-radius;
      > li {
        &.active {
          background-color: $app-blue;
        }
        a {
          display: flex;
          align-items: center;
          padding: 5px 10px;
        }
        img {
          width: 35px;
          height: 25px;
          border-radius: $border-radius;
          border: $default-border;
          margin-right: 5px;
        }
      }
    }
  }
}
