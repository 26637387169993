@import '../../../core/styles/_variables.scss';

.user-settings-trading-info {
  width: 100%;
  border: $default-border;
  border-radius: $border-radius;
  > div {
    display: flex;
    align-items: center;
    &:first-of-type {
      min-height: 40px;
      border-bottom: $default-border;
    }
    &:not(:first-of-type) {
      min-height: 75px;
    }
    &:nth-of-type(2n + 2) {
      background: $app-grey3;
    }
    > div {
      &:nth-child(1) {
        width: 10%;
        text-align: center;
      }
      &:nth-child(2) {
        width: 65%;
        > div {
          &:first-of-type {
            font-size: 16px;
            font-weight: $font-weight-medium;
          }
          &:not(:first-of-type) {
            color: $app-grey1;
          }
        }
      }
      &:nth-child(3) {
        width: 25%;
        min-width: 170px;
        display: flex;
        align-items: center;
        .icn {
          margin-right: 5px;
        }
        .icn-circle-check {
          color: $app-green1;
        }
        .icn-alert {
          color: $app-orange;
        }
      }
    }
  }
  @media (max-width: $mobile-resolution-max-width) {
    border: 0;
    > div {
      flex-direction: column;
      align-items: flex-start;
      padding: 10px 0;
      &:first-of-type {
        display: none;
      }
      &:nth-of-type(2n + 2) {
        background: transparent;
      }
      > div {
        &:nth-child(1) {
          width: 100%;
          text-align: left;
          color: $app-grey1;
          > div:last-of-type {
            position: relative;
            overflow: hidden;
            span {
              font-size: 11px;
              font-weight: $font-weight-medium;
              &:after {
                content: '';
                position: absolute;
                margin-left: 10px;
                top: 50%;
                width: 9999px;
                height: 1px;
                background: #dfe3e9;
              }
            }
          }
        }
        &:nth-child(2) {
          width: 100%;
          margin-top: 10px;
        }
        &:nth-child(3) {
          width: 100%;
          margin-top: 15px;
          margin-bottom: 15px;
        }
      }
    }
  }
}
