@import '../../../../core/styles/variables';

.status-circle {
  width: 10px;
  height: 10px;
  border-radius: 50px;
  margin-bottom: 0px;
  margin-left: 5px;
  &.green {
    background-color: $app-green1;
  }
  &.red {
    background-color: $app-red;
  }
}
