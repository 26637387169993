@import '../../../core/styles/_variables.scss';

.user-settings-security {
  display: flex;
  flex-direction: column;
  position: relative;
  > div {
    padding: 20px;
    &:first-of-type:not(.mfa-authenticator):not(.mfa-changed) {
      border-bottom: $default-border;
    }
  }
}
