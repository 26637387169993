@import '../../../../core/styles/_variables.scss';

.trade-history-item {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  padding: 5px 15px;
  text-align: left;
  align-items: center;
  > div {
    width: 33%;
  }
  &__amount {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    &.buy {
      color: $app-green1;
    }
    &.sell {
      color: $app-red;
    }
    i {
      margin-right: 5px;
    }
  }
  &__time {
    text-align: right;
    color: $app-grey1;
  }
  &:hover {
    cursor: default;
    background: $app-grey3;
  }
  &.item-title {
    color: $app-grey1;
    margin-bottom: 5px;
  }
}
