@import '../../../../core/styles/_variables.scss';

.info-modal {
  display: flex !important;
  align-items: center;
  .modal-content {
    max-width: 420px;
    min-width: 300px;
  }
  .modal-dialog {
    max-width: 420px;
    min-width: 300px;
    margin: 0px auto;
  }
  .info-modal-header {
    border: none;
    border-radius: 4px;
    display: flex;
    align-items: center;
    color: white;
    .close {
      color: white;
      opacity: 1;
      position: absolute;
      top: 10px;
      right: 10px;
    }
    img {
      height: 25px;
    }
    p {
      color: #fafbfc;
      margin-left: 20px;
      margin-right: 20px;
      line-height: 20px;
      margin-bottom: 0px;
    }
    &.success {
      background: #5acf73;
    }
    &.info {
      background: white;
      .close,
      p {
        color: #172b4d;
      }
    }
    &.warning {
      background: #ffc400;
      .close,
      p {
        color: #172b4d;
      }
    }
    &.error {
      background: #de350b;
    }
  }
}
