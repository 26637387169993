@import '../../../../core/styles/_variables.scss';
@import '../../../../core/styles/_mixins.scss';

.order-book {
  &__spread {
    margin: 5px 0px;
    padding: 10px 0px;
    border-top: $default-border;
    border-bottom: $default-border;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: right;
    &-text {
      width: calc(75px + 37%);
      color: $app-grey1;
    }
    &-value {
      width: 37%;
      padding-right: 15px;
      font-weight: $font-weight-medium;
    }
  }
  &__sell-container {
    height: 329px;
    overflow-y: auto;
    &-hide {
      display: none;
    }
    &-expand {
      height: 710px;
    }
  }
  &__separator {
    border-top: $default-border;
    border-bottom: $default-border;
    margin: 10px 0px;
    padding: 5px 0px;
    text-align: center;
    &-hide {
      display: none;
    }
  }
  &__buy-container {
    height: 329px;
    overflow-y: auto;
    &-hide {
      display: none;
    }
    &-expand {
      height: 710px;
    }
  }
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 15px;
    color: $app-grey1;
    @include small-text;
    > div {
      width: 33%;
      text-align: left;
      &:last-child {
        text-align: right;
      }
    }
  }
  &__show-option {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 10px 15px;
    > div {
      margin-right: 10px;
      background-size: contain;
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      opacity: 0.3;
      &:hover {
        cursor: pointer;
        opacity: 1;
      }
      &.selected-book-option {
        opacity: 1;
      }
    }
    &__buy-sell {
      background-image: url('../images/ico_buysell.svg');
    }
    &__buy-buy {
      background-image: url('../images/ico_buybuy.svg');
    }
    &__sell-sell {
      background-image: url('../images/ico_sellsell.svg');
    }
  }
  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: calc(50% - 40px);
    position: relative;
    .mini-loader {
      position: relative;
      margin: 0px;
    }
  }
  &__no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: calc(50% - 35px);
    color: $app-grey1;
    &-title {
      position: absolute;
      top: 10px;
      left: 10px;
      color: $app-black;
    }
  }
}
