@import '../../../core/styles/_variables.scss';

.open-order {
  padding: 10px;
  min-width: 300px;
  width: auto;
  &__type {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: -8px -10px 0 -10px;
    padding: 8px 10px 8px 10px;
    border-bottom: $default-border;
    &--buy {
      color: $app-green2;
    }
    &--sell {
      color: $app-red;
    }
    > div {
      &:first-of-type {
        font-weight: $font-weight-medium;
        font-size: 14px;
      }
      &:last-of-type {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        color: $app-grey1;
        font-weight: $font-weight-medium;
        font-size: 12px;
      }
    }
  }
  &__symbol {
    display: flex;
    align-items: center;
    margin: 24px 0;
    &__info {
      margin-left: 16px;
      color: $app-grey1;
      font-size: 12px;
      &__name {
        display: flex;
        align-items: center;
        color: $app-black;
        font-size: 14px;
        font-weight: $font-weight-medium;
      }
      &__fee {
        font-size: 12px;
        color: $app-grey1;
        margin-left: 5px;
      }
    }
    &__data {
      display: flex;
      align-items: center;
      margin-bottom: 32px;
      &__price-label {
        color: $app-grey1;
        font-size: 12px;
        margin-right: 8px;
      }
      .amount {
        color: $app-black;
        font-size: 14px;
      }
    }
    &__sign-up-buttons {
      display: flex;
      position: fixed;
      bottom: 0;
      width: 100%;
      z-index: 99;
      padding: 0;
      &.iphone-x {
        padding-bottom: 20px;
        background-color: $app-white;
      }
      .button-container__buttons {
        width: 100%;
        height: 40px;
        padding: 0;
        > button {
          border: 0;
        }
      }
      button {
        width: 50%;
        border-radius: 0;
        margin: 0;
        color: $app-white;
        font-weight: $font-weight-medium;
        overflow: hidden;
        text-overflow: ellipsis;
        &:first-of-type {
          background-color: $app-blue;
          &:hover,
          &:active {
            color: $app-white;
            background-color: darken($app-blue, 10%);
          }
        }
        &:last-of-type {
          background-color: $app-black;
          &:hover,
          &:active {
            color: $app-white;
            background-color: darken($app-black, 10%);
          }
        }
      }
    }
  }
  &__limit-market-toggle {
    margin-bottom: 16px;
  }
  &.mobile {
    .open-order__symbol {
      margin-top: 5px;
      &__info {
        &__name {
          flex-direction: column;
          align-items: flex-start;
        }
        &__fee {
          margin-left: 0;
        }
      }
    }
  }
  .buy-sell-button {
    margin-top: 14px;
  }
  &__log-in {
    text-align: center;
    margin-top: 30px;
  }
  &__pair-prices {
    width: 100%;
    &__row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:first-of-type {
        margin-bottom: 10px;
      }
      &-text {
        width: 30%;
      }
      &-input {
        width: 70%;
        input[type='text'] {
          width: 100%;
          height: 40px;
          border-radius: $border-radius;
          border: $default-border;
          padding-left: 10px;
        }
      }
    }
  }
}

.blue-toggle {
  .btn {
    padding: 0 10px;
    border: none;
    background-color: transparent;
    color: $app-blue;
    font-size: 12px;
    font-weight: $font-weight-regular;
    &.btn-default:hover {
      color: $app-blue-active;
      background-color: transparent;
    }
    &.btn-default:active {
      color: $app-blue-active;
      background-color: transparent;
      box-shadow: none;
    }
    &.btn-default.active {
      background-color: $app-blue-active;
      color: $app-white-color;
      font-weight: $font-weight-medium;
      border: 1px solid $app-blue-active;
    }
  }
}
