@import '../../../../core/styles/_variables.scss';

.order-book-modal {
  .order-book__show-option__buy-sell {
    display: none;
  }
  .modal-dialog {
    height: calc(100% - 10px);
    .modal-header {
      .close {
        font-size: 26px;
      }
    }
    .modal-content {
      height: 100%;
      overflow: auto;
    }
    .order-book__buy-container,
    .order-book__sell-container {
      &-expand {
        height: 100%;
      }
    }
    @media (orientation: landscape) {
      width: 80%;
    }
  }

  .icn-simple-remove-big-thin {
    position: absolute;
    top: 15px;
    right: 10px;
    font-size: 18px;
    font-weight: $font-weight-bold;
  }
  &.ios {
    .order-book__buy-container,
    .order-book__sell-container {
      &-expand {
        height: calc(100% - 110px);
        @media (orientation: landscape) {
          height: calc(100% - 105px);
        }
      }
    }
  }
}
