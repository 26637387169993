@import '../../../core/styles/_variables.scss';

.investment-section {
  .enter-value {
    padding-bottom: 5px;
  }
  &__toggle {
    width: 100%;
    padding: 0px 4px;
    margin-top: 5px;
    display: flex;
    justify-content: flex-end;
    .btn-toolbar {
      width: 100%;
      @media screen and (max-width: $mobile-resolution-max-width) {
        width: 70%;
      }
      @media screen and (max-width: 404px) {
        width: 100%;
      }
      .btn-group {
        display: flex;
        width: 100%;
        margin: 0px;
        .btn {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          padding: 2px;
          border: $default-border;
          border-radius: 0;
          background-color: #fff;
          color: $app-indigo-light-color;
          font-weight: $font-weight-regular;
          font-size: 12px;
          &:first-of-type {
            border-top-left-radius: $border-radius;
            border-bottom-left-radius: $border-radius;
          }
          &:last-of-type {
            border-top-right-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
          }
          &:hover {
            border-color: $app-blue;
          }
        }
        .btn.active {
          box-shadow: none;
          background-color: $app-white-color;
          color: $app-blue;
          border-color: $app-blue;
        }
      }
    }
  }
}

.investment-explanation {
  font-size: 10px;
  color: $app-grey1;
  margin-top: 12px;
}
