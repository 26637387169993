@import '../../../core/styles/_variables.scss';

.wallet {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    &__search {
      position: relative;
      i {
        position: absolute;
        left: 10px;
        top: 20px;
        color: $app-grey1;
      }
      input {
        width: 280px;
        border: $default-border;
        border-radius: $border-radius;
        padding-left: 30px;
        height: 36px;
        margin: 10px 0px;
        background: $app-grey3;
      }
    }
    &__info {
      display: flex;
      align-items: center;
      div:first-of-type {
        div:first-child {
          color: $app-grey1;
          text-align: right;
        }
        div:last-child {
          font-size: 12px;
        }
      }
      .btn {
        color: $app-blue;
        font-weight: $font-weight-medium;
        margin-right: 20px;
        &:hover,
        &:focus,
        &:active,
        &:active:hover,
        &:active:focus {
          color: $app-blue;
        }
      }
    }
    &.mobile {
      flex-direction: column;
      align-items: initial;
      .wallet__header__info {
        flex-direction: column;
        > div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin: 20px 0 5px;
        }
        .btn {
          width: 100%;
          margin-right: 0;
        }
      }
      .wallet__header__search {
        order: 2;
        input {
          font-size: 15px;
        }
        i {
          top: 21px;
        }
      }
    }
  }
  &__create-account {
    display: flex;
    align-items: center;
    margin: 0 20px 20px;
    padding: 20px;
    border: $default-border;
    border-radius: $border-radius;
    &__heading-text {
      font-size: 12px;
      color: $app-grey1;
      font-weight: $font-weight-medium;
    }
    &__title {
      margin-top: 10px;
      font-size: 20px;
      font-weight: $font-weight-medium;
    }
    &__note {
      margin-top: 15px;
      color: $app-grey1;
    }
    .btn {
      margin-top: 20px;
    }
    @media (max-width: $mobile-resolution-max-width) {
      border-left: 0;
      border-bottom: 0;
      border-right: 0;
      border-radius: 0;
      margin: 0;
    }
  }
}
