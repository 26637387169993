@import '../../../../core/styles/_variables.scss';

.order-book-item {
  display: flex;
  height: 25px;
  align-items: center;
  font-size: 12px;

  text-align: right;
  justify-content: space-between;
  position: relative;
  margin-bottom: 2px;
  &__graph {
    position: absolute;
    width: 100%;
    height: 100%;
    &-line {
      width: 100%;
      height: 100%;
    }
  }
  > div:not(.order-book-item__graph) {
    width: 33%;
  }
  &__price {
    z-index: 2;
    text-align: left;
    padding-left: 15px;
    font-weight: $font-weight-medium;
    &.sell {
      color: $app-red;
    }
    &.buy {
      color: $app-green1;
    }
    &:hover {
      font-weight: $font-weight-bold;
      cursor: pointer;
    }
  }
  &__amount {
    text-align: right;
    margin-right: 15px;
    z-index: 99;
    &:hover {
      font-weight: $font-weight-bold;
      cursor: pointer;
    }
    &.sell {
      color: $app-red;
    }
    &.buy {
      color: $app-green1;
    }
  }
  > div:last-child {
    font-weight: $font-weight-medium;
  }
  &:hover {
    cursor: default;
    background: $app-grey3;
  }
}
