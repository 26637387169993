@import '../../../core/styles/_mixins.scss';

.registration-wrapper {
  display: flex;
  justify-content: center;
  height: 100%;
  background-color: $app-grey-bright-color;
  .registration-form {
    width: 100%;
    height: 100%;
    max-width: 480px;
    background: $app-white-color;
    border: $default-border;
    border-radius: $border-radius;
    @media screen and (max-width: $mobile-resolution-max-width) {
      margin-top: 65px;
      border-bottom: none;
    }
    .input-and-error {
      min-height: 55px;
      height: auto;
    }
    &__header {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-bottom: 40px;
      padding: 30px 30px 0px 30px;
      @media screen and (max-width: $mobile-resolution-max-width) {
        padding: 30px 10px 0px 10px;
      }
    }
    &__name {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .default-input {
        width: 49%;
      }
    }
    &__footer {
      padding: 10px 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: $default-border;
      background: $app-grey3;
      min-height: 45px;
      @media screen and (max-width: $mobile-resolution-max-width) {
        padding: 10px;
      }
      > span {
        margin-right: 5px;
        color: $app-grey1;
      }
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      flex: 1;
      .error {
        @include error-label-styles;
      }
      @include error-input-styles;
    }
    &__title {
      text-align: center;
      margin: 0;
      font-size: 24px;
      font-weight: $font-weight-medium;
    }
    &__body {
      display: flex;
      flex: 1;
      flex-direction: column;
      width: 100%;
      padding: 0px 30px;
      min-height: 450px;
      height: 100%;
      background-color: $app-white-color;
      @media screen and (max-width: $mobile-resolution-max-width) {
        padding: 0px 10px;
      }
    }
    &__terms-and-conditions {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      @media screen and (max-width: $mobile-resolution-max-width) {
        margin-bottom: 30px;
      }
      input {
        margin: 0;
      }
      > span {
        font-size: 12px;
        margin-left: 5px;
      }
    }
    &__submit-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 25px;
    }
    &__sign-up {
      align-self: flex-start;
      width: 100%;
      margin-bottom: 10px;
      .mini-loader {
        position: static;
        height: 18px;
        width: 18px;
        margin: -2px 16px 0;
      }
    }
    &__country-wrapper {
      label {
        font-weight: $font-weight-regular;
      }
      .select-and-error {
        position: relative;
        height: 55px;
      }
    }
    &__phone-wrapper {
      .phone-number {
        input {
          padding-left: 85px;
        }
      }
    }
    &__phone-code-wrapper {
      width: 120px;
      top: 35px;
      margin-top: -35px;
      position: relative;
      z-index: 1;
      &.is-open {
        width: calc(100% - 2px);
        .phone-select__control {
          border: 0;
          border-top-right-radius: $border-radius;
          border-bottom-right-radius: $border-radius;
        }
      }
    }
    &__select {
      .country-select,
      .phone-select {
        height: 36px;
        &__control,
        &__control--is-focused {
          height: 36px;
          min-height: 36px;
          border: $default-border;
          border-radius: $border-radius;
          box-shadow: none;
        }
        &__menu {
          z-index: 2;
        }
        &__single-value,
        &__option {
          > div {
            display: flex;
            align-items: center;
          }
        }
        &__control,
        &__menu {
          .country-flag {
            width: 30px;
            height: 20px;
            margin-right: 10px;
            border: $default-border;
          }
        }
      }

      .phone-select {
        &__control {
          height: 34px;
          min-height: 34px;
          width: 80px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border: 0;
          border-right: $default-border;
          margin-left: 1px;
          background-color: $app-grey-bright-color;
          &--is-focused {
            width: 100%;
          }
        }
        &__indicator-separator {
          display: none;
        }
        &__dropdown-indicator {
          padding-left: 0px;
          padding-right: 5px;
        }
      }

      @include error-input-styles;
    }
    &__social-login {
      display: flex;
      > div {
        display: flex;
        align-items: center;
      }
      span {
        color: $app-indigo-light-color;
      }
      img {
        height: 15px;
      }
      button {
        width: 36px;
        padding: 5px;
        margin-left: 10px;
      }
    }
    &__error {
      margin-bottom: 15px;
      padding: 8px 10px;
      border-radius: 0;
      border: 0;
      margin-top: -25px;
      border-top: 2px solid $app-red-color;
      color: $app-red-color;
      background-color: $app-red-light-color;
      font-size: 12px;
    }
    .default-select {
      margin-bottom: 20px;
      label {
        font-size: 14px;
        font-weight: $font-weight-regular;
      }
      select {
        height: 36px;
        font-size: 14px;
        border: $default-border;
        background-color: $app-white-color;
      }
    }
    .btn-link {
      font-weight: $font-weight-medium;
    }
    .Select-menu-outer {
      z-index: 2;
      border: $default-border;
    }
    a:hover {
      text-decoration: none;
      cursor: pointer;
    }
  }

  .authorization-panel {
    max-width: 350px;
  }
}
.register-modal,
.login-modal {
  .modal-dialog {
    width: fit-content;
  }

  .modal-body {
    padding: 0px;
  }
  .registration-wrapper,
  .login-wrapper {
    padding: 0px;
    background-color: transparent;
    .registration-form,
    .login-form {
      &__wrapper {
        width: 415px;
        @media (max-width: $mobile-resolution-max-width) {
          width: 100%;
        }
      }
    }
    .registration-form__wrapper {
      padding-top: 50px;
    }
  }
  &__close-wrapper {
    i {
      font-size: 22px;
      cursor: pointer;
    }
    position: absolute;
    float: right;
    right: 15px;
    top: 10px;
    @media (max-width: $mobile-resolution-max-width) {
      top: 75px;
    }
  }
  @media (max-width: $mobile-resolution-max-width) {
    .modal-content,
    .modal-dialog {
      width: 100%;
    }
    .login-form,
    .registration-form {
      width: 100%;
      border: none;
      &__wrapper {
        width: auto;
      }
    }
  }
}
