@import '../../../../core/styles/_variables.scss';

@keyframes rotate-loader {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeOut {
  80% {
    opacity: 0.8;
  }

  100% {
    opacity: 0.3;
  }
}

.loading-container {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  .loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    height: 100%;
    .spinner-wrapper {
      position: relative;
      width: 120px;
      height: 120px;
      .loader-inner {
        background-color: #333642;
        border-radius: 35px;
        animation: rotate-loader 1s 0s linear infinite;
        width: 120px;
        height: 120px;
      }
      .ss-logo {
        width: 25px;
        height: 60px;
        position: absolute;
        z-index: 1;
        animation: fadeOut 1s linear infinite;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        img {
          width: 25px;
          height: 60px;
        }
      }
    }
    span {
      font-size: 12px;
      position: relative;
      top: 35px;
      min-height: 20px;
    }
    label {
      margin-top: 20px;
      color: $app-grey-bright-color;
    }
    button {
      position: absolute;
      top: 100%;
      left: 5%;
      margin-top: -57px;
      width: 90%;
    }
  }
}
