@import '../../../core/styles/_variables.scss';

.browser-not-supported {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background: $app-white;
  &__image {
    width: 106px;
    margin-bottom: 50px;
  }
  &__title {
    font-size: 24px;
    margin-bottom: 20px;
  }
  &__message {
    color: $app-grey1;
    font-size: 16px;
    width: 336px;
    text-align: center;
    margin-bottom: 60px;
    @media screen and (max-width: 320px) {
      width: 300px;
    }
  }
}

.supported-browsers-list {
  display: flex;
  justify-content: space-between;
  width: 40%;
  @media screen and (max-width: $mobile-resolution-max-width) {
    width: 80%;
  }
}
