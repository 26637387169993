@import '../../../core/styles/_variables.scss';

.exchange {
  display: flex;
  flex-direction: column;
  &__top {
    display: flex;
    justify-content: space-between;
  }
  &__left {
    width: calc(75% - 10px);
    &__overview {
      background-color: #fff;
      margin-bottom: 10px;
      border: $default-border;
      border-radius: $border-radius;
    }
    &__main {
      display: flex;
      justify-content: space-between;
      > div {
        &:first-of-type {
          width: 30%;
          min-width: 280px;
          .order-book {
            background-color: $app-white;
            border: $default-border;
            border-radius: $border-radius;
          }
        }
        &:last-of-type {
          width: calc(70% - 10px);
        }
        @media (max-width: $mobile-resolution-max-width) {
          &:first-of-type,
          &:last-of-type {
            width: 100%;
          }
        }
      }
      .order-book {
        min-height: 340px;
        position: relative;
      }
      .no-order-book-data {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 340px;
        position: relative;
        > div {
          &:first-of-type {
            position: absolute;
            top: 10px;
            left: 10px;
          }
          &:last-of-type {
            color: $app-grey1;
          }
        }
      }
    }
    &__chart {
      height: 340px;
      background-color: #fff;
      margin-bottom: 10px;
      border: $default-border;
      border-radius: $border-radius;
      &__trading-view {
        height: 440px;
        width: 100%;
      }
    }
    &__orders {
      &__tabs-container {
        .btn-group {
          > .btn:last-child:not(:first-child) {
            border-right: 0;
          }
        }
      }
    }
    .exchange-open-order {
      min-width: 620px;
      .orders_loader {
        width: 100%;
        min-height: 235px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          position: relative;
          margin: 0px;
        }
      }
    }
  }
  &__right {
    width: 25%;
    min-width: 280px;
    &__markets {
      background-color: #fff;
      margin-bottom: 10px;
      border: $default-border;
      border-radius: $border-radius;
    }
    &__trade-history {
      background-color: #fff;
      border: $default-border;
      border-radius: $border-radius;
      flex: 1;
      text-align: center;
      min-height: 300px;
      &__title {
        display: flex;
        align-self: flex-start;
        padding: 10px;
      }
    }
  }
  &__bottom {
    width: 100%;
    margin-top: 10px;
    .exchange-orders {
      margin-bottom: 10px;
      .orders_loader {
        width: 100%;
        min-height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          position: relative;
          margin: 0px;
        }
      }
    }
  }
  .tabs-toggle {
    border-top: 0;
  }

  @media screen and (max-width: $mobile-resolution-max-width) {
    flex-direction: column;
    margin-bottom: 25px;
    &__top {
      flex-direction: column;
    }
    &__left {
      width: 100%;
      &__overview,
      &__chart {
        border-radius: 0;
        border-left: 0;
        border-right: 0;
      }
    }
    &__right {
      width: 100%;
      &__trade-history {
        border-radius: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 0;
      }
    }
    &__bottom {
      margin-top: 0;
      &.is-ios {
        margin-bottom: 30px;
      }
    }
    &.iphone-x {
      margin-bottom: 40px;
    }
  }
}
