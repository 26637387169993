@import '../../../core/styles/_variables.scss';
@import '../../../core/styles/_mixins.scss';

.refered-friend-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  @media screen and (max-width: $mobile-resolution-max-width) {
    flex-direction: column;
    &:nth-child(odd) {
      background: $app-grey3;
    }
  }
  &:nth-child(even) {
    background: $app-grey3;
    @media screen and (max-width: $mobile-resolution-max-width) {
      background: $app-white;
    }
  }
  &__column {
    width: 20%;
  }
  &.title {
    border-bottom: $default-border;
  }
  &__data {
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 450px) {
      justify-content: flex-start;
      flex-wrap: wrap;
    }
    &:first-of-type {
      margin-bottom: 20px;
      justify-content: flex-start;
    }
    > div {
      width: 33%;
      @media screen and (max-width: 450px) {
        width: 40%;
      }
      @media screen and (max-width: 350px) {
        width: 50%;
      }
      > div:first-child {
        color: $app-grey1;
      }
    }
  }
}
