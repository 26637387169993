@import '../../../core/styles/variables.scss';

.banner {
  padding: 5px;
  &__content {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #7b808e;
    &--reverse {
      flex-direction: row-reverse;
      .banner {
        &__thumbnail {
          &-image {
            margin-right: 10px;
            margin-left: 0;
          }
        }
      }
    }
  }
  &__content-wrapper {
    display: flex;
    align-items: flex-start;
    width: 100%;
  }
  &__icon.icn {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 30px;
    min-height: 30px;
    margin-right: 10px;
    &.success {
      color: $app-green-color;
      border: 1px solid $app-green-color;
      border-radius: 50%;
      &::before {
        content: '\ea70';
      }
    }
    &.error {
      color: $app-red-color;
      border: 1px solid $app-red-color;
      border-radius: 50%;
      &::before {
        content: '\ea03';
      }
    }
  }
  &__title {
    font-size: 16px;
    color: $app-indigo-dark-color;
  }
  &__thumbnail {
    &-image {
      width: 50px;
      height: 50px;
      margin-left: 10px;
      background-size: cover;
      background-position: center;
    }
  }
  &__actions {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    &--single {
      justify-content: center;
    }
    &--accept {
      background: $app-blue;
      height: 36px;
      min-width: 70px;
      color: $app-white-color;
    }
    &--reject {
      height: 36px;
      color: $app-indigo-dark-color;
      min-width: 70px;
    }
    button {
      width: 48%;
      font-weight: $font-weight-medium;
      font-size: 14px;
      &:hover,
      &:focus,
      &:active {
        opacity: 0.9;
        outline: none;
      }
    }
  }
}
