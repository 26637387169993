@import '../../../core/styles/_mixins.scss';
@import '../../../core/styles/_variables.scss';

.mfa {
  height: 100%;
  @media screen and (max-width: $mobile-resolution-max-width) {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  &-wrapper {
    min-height: 520px;
    display: flex;
    height: 100%;
    padding: 0px 22px;
    text-align: center;
    justify-content: center;
    @media screen and (max-width: $mobile-resolution-max-width) {
      height: calc(100% - 65px);
      padding: 0px;
      margin-top: 65px;
      flex-direction: column;
    }
    &__content {
      padding: 30px;
      min-height: 100%;
      width: 100%;
      max-width: 480px;
      background: #fff;
      display: flex;
      flex-direction: column;
      &__header-container {
        &__title {
          @include h2;
        }
        &__description {
          @include p;
          color: $app-grey1;
          margin-top: 15px;
        }
      }
      form {
        margin-top: 30px;
      }
      button {
        width: 100%;
      }
      &__back {
        margin-top: auto;
      }
      &__error {
        margin-bottom: 15px;
        padding: 8px 10px;
        border-radius: 0;
        border: 0;
        border-top: 2px solid $app-red-color;
        color: $app-red-color;
        background-color: $app-red-light-color;
        font-size: 12px;
      }
    }
  }
}
