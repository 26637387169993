@import '../../../core/styles/variables.scss';

.flex-grid {
  display: flex;
  justify-content: space-between;
  .col {
    padding: 20px;
    background: $app-indigo-dark-color;
  }
}

.app-layout {
  display: flex;
  flex-direction: column;
  .app-layout__body {
    display: flex;
    flex: 1 1 100% !important;
    flex-direction: column;
    align-items: center;
    background: $app-grey-bright-color;
    color: $app-indigo-dark-color;
  }
}

.app-content {
  flex: 1;
  height: inherit;
  margin: 15px;
  max-width: $desktop-resolution-max-width;
  width: 100%;
  background: #fff;
  border: $default-border;
  border-radius: $border-radius;
  width: calc(100% - 30px);
  @media (max-width: $mobile-resolution-max-width) {
    margin: 15px 0px;
    width: 100%;
  }
  .back-to-dashboard-box {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-left: 20px;
    margin-top: 20px;
    cursor: pointer;
    &__img {
      height: 15px;
    }
    &__title {
      margin-bottom: 0px;
      margin-left: 20px;
      font-size: 16px;
      color: #74c342;
    }
  }
}

// mobile device browsers have to have min 16px font size on form controls
// in order to stop zooming in when focusing on inputs or any other form control

@media (max-width: $mobile-resolution-max-width) {
  input[type='color'],
  input[type='date'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='email'],
  input[type='month'],
  input[type='number'],
  input[type='password'],
  input[type='search'],
  input[type='tel'],
  input[type='text'],
  input[type='time'],
  input[type='url'],
  input[type='week'],
  select:focus,
  textarea {
    font-size: 16px;
  }
}
