@import '../../../core/styles/_variables.scss';

.btn.buy-sell-button {
  &.loading {
    display: flex;
    align-content: center;
    justify-content: center;
  }
  &:hover,
  &:active,
  &:focus,
  &:focus:active {
    color: $app-white-color;
  }
  &.market-closed,
  &.market-closed:disabled:hover {
    background-color: $app-grey-bright-color;
  }
  .mini-loader {
    position: static;
    height: 20px;
    width: 20px;
    margin: 0;
  }
}
