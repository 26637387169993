@import '../../../core/styles/_variables.scss';

.mfa-authenticator {
  display: flex;
  flex-direction: column;
  &__description {
    color: $app-grey1;
    margin: 5px 0;
    &.mfa-apps {
      > span {
        span {
          color: $app-blue;
          font-weight: $font-weight-medium;
        }
      }
    }
  }
  &__code {
    display: flex;
    flex-direction: column;
    > span:last-of-type {
      font-weight: $font-weight-medium;
      margin-top: 5px;
    }
  }
  &__back {
    margin-bottom: 10px;
    button {
      display: flex;
      align-items: center;
      .icn {
        margin-right: 4px;
      }
    }
  }
  &__form {
    margin: 10px 0;
    form {
      display: flex;
      align-items: flex-start;
      .default-input {
        min-width: 240px;
        max-width: 240px;
        margin-right: 10px;
      }
      @media screen and (max-width: 370px) {
        .default-input {
          width: 100%;
          min-width: 190px;
          margin-right: 0;
          input {
            font-size: 14px;
          }
        }
      }
    }
  }
  &__footer {
    margin-top: 30px;
    font-size: 12px;
    color: $app-grey1;
  }
}
