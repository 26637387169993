@import '../../../core/styles/_variables.scss';

.wallet-currency-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
  @media screen and (min-width: $mobile-resolution-max-width) {
    &:nth-child(odd) {
      background-color: $app-grey-bright-color;
    }
  }
  &__data {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: calc(100% - 236px);
    &__item {
      width: 25%;
      padding: 10px 0px;
      color: $app-black;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 16px;
      &:first-child {
        width: 25%;
        > span {
          width: calc(100% - 41px);
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
      &__image {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: $app-grey2;
        margin-right: 10px;
        img {
          width: 40px;
          height: 40px;
        }
      }
      &-currency {
        display: flex;
        flex-direction: column;
        span:last-child {
          font-size: 12px;
        }
      }
    }
  }
  &__actions {
    display: flex;
    padding-right: 20px;
    text-align: right;
    color: $app-grey1;
    font-size: 16px;
    :hover {
      cursor: pointer;
      color: $app-blue;
    }
    button:first-child {
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    button:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  &.mobile {
    padding: 10px 20px;
    border-top: $default-border;
    &:last-child {
      border-bottom: $default-border;
    }
  }
  @media screen and (max-width: $mobile-resolution-max-width) {
    display: block;
    padding-left: 0px;
    &__data {
      &__item {
        &:first-child {
          width: 100%;
        }
        &-currency {
          width: 80%;
          overflow: visible;
        }
        &-desc {
          font-size: 14px;
        }
        &__body {
          display: flex;
          align-items: center;
          margin-top: 10px;
          margin-bottom: 20px;
          @media screen and (max-width: 375px) {
            flex-wrap: wrap;
          }
          &__total {
            width: 33%;
            @media screen and (max-width: 375px) {
              width: 50%;
            }
            &-title {
              color: $app-grey1;
            }
          }
          &__in-order {
            width: 33%;
            @media screen and (max-width: 375px) {
              width: 50%;
            }
            &-title {
              color: $app-grey1;
            }
          }
          &__est-value {
            width: 33%;
            @media screen and (max-width: 375px) {
              width: 50%;
              margin-top: 10px;
            }
            &-title {
              color: $app-grey1;
            }
          }
        }
        &__buttons {
          width: 100%;
          height: 40px;
          margin-bottom: 10px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          button {
            flex: 1;
            &:first-child {
              border-right: none;
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }
            &:last-child {
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $mobile-resolution-max-width) {
}
