@import '../../../../core/styles/variables.scss';

.main-navigation {
  height: 60px;
  max-width: $desktop-resolution-max-width;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__items {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: -1px;
    height: $app-main-navigation-height;
    padding-left: 15px;
    @media (min-width: 1300px) {
      padding-left: 0px;
    }
  }
  &__additional-fields {
    padding-right: 15px;
    @media (min-width: 1300px) {
      padding-right: 0px;
    }
  }
  &__balance {
    margin-right: 15px;
    outline: none;
    &-green {
      color: $app-green-text-color;
    }
    &-red {
      color: $app-red-color;
    }
    .btn-default {
      border: none;
      padding-left: 0px;
      color: $app-indigo-light-color;
      padding-right: 0px;
      font-size: 16px;
      span.amount {
        margin-left: 5px;
        &.positive {
          color: $app-green-text-color;
        }
        &.warning {
          color: $app-orange;
        }
        &.negative {
          color: $app-red;
        }
      }
    }
    .btn:active,
    .btn.active {
      box-shadow: none;
    }
    .btn-default:active,
    .btn-default:hover,
    .btn-default:focus,
    .open > .dropdown-toggle.btn-default:hover,
    .open > .dropdown-toggle.btn-default,
    .btn-default:active:hover,
    .btn-default.active:hover,
    .open > .dropdown-toggle.btn-default:hover,
    .btn-default:active:focus,
    .btn-default.active:focus,
    .open > .dropdown-toggle.btn-default:focus,
    .btn-default:active.focus,
    .btn-default.active.focus,
    .open > .dropdown-toggle.btn-default.focus {
      background: #fff;
      color: $app-indigo-light-color;
      border: none;
    }
    .btn:focus,
    .btn:active:focus,
    .btn.active:focus,
    .btn.focus,
    .btn:active.focus,
    .btn.active.focus {
      outline: none;
    }
    .btn-group.open .dropdown-toggle {
      box-shadow: none;
    }
    .dropdown-menu {
      border-radius: $border-radius;
      border: $default-border;
      min-width: 230px;
      padding: 0px;
      &:before {
        position: absolute;
        top: -7px;
        left: 180px;
        display: inline-block;
        border-right: 7px solid transparent;
        border-bottom: 7px solid $app-grey-light-2-color;
        border-left: 7px solid transparent;
        border-bottom-color: rgba(0, 0, 0, 0.2);
        content: '';
      }

      &:after {
        position: absolute;
        top: -6px;
        left: 181px;
        display: inline-block;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #ffffff;
        border-left: 6px solid transparent;
        content: '';
      }
      li a {
        padding: 7px 12px;
        display: flex;
        span.amount {
          margin-left: 5px;
          &.positive {
            color: $app-green-text-color;
          }
          &.warning {
            color: $app-orange;
          }
          &.negative {
            color: $app-red;
          }
        }
        .btn-block {
          i {
            vertical-align: middle;
            width: 18px;
            font-size: 18px;
            margin-right: 10px;
          }
          .mini-loader {
            position: static;
            height: 20px;
            width: 20px;
            margin: -2px 20px 0;
          }
        }
        > div {
          width: 42%;
        }
      }
      li:first-child a {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
      }
      li:last-child a:hover {
        background: #fff;
        cursor: default;
      }
      li.active a {
        background: #fff;
        color: #333333;
        border-left: 1px solid $app-blue;
      }
    }
  }
  &__item {
    margin-right: 20px;
    height: 100%;
    display: flex;
    &--disabled {
      pointer-events: none;
    }
    img {
      height: 18px;
    }
    span:not(.new-feature-badge) {
      font-size: 14px;
      font-weight: 400;
      color: $app-grey1;
      margin-left: 2px;
      margin-right: 2px;
      pointer-events: none;
    }
    div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-bottom: 1px solid transparent;
      &:hover {
        span:not(.new-feature-badge) {
          color: $app-black;
        }
      }
    }
    &.active {
      div {
        border-bottom: 1px solid $app-blue;
        span:not(.new-feature-badge) {
          font-weight: $font-weight-medium;
          color: $app-blue;
        }
      }
    }
    &:hover,
    &:active,
    &:link {
      text-decoration: none;
    }
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .main-navigation__item {
    margin-right: 30px;
    span:not(.new-feature-badge) {
      font-size: 16px;
      font-weight: $font-weight-regular;
    }
  }
}

@media (max-width: $mobile-resolution-max-width) {
  .main-navigation {
    &__items {
      a {
        font-size: 16px;
      }
      .btn-default {
        border: none;
        padding-left: 0px;
        font-size: 16px;
        color: $app-blue;
      }
      .btn:active,
      .btn.active {
        box-shadow: none;
      }
      .btn-default:active,
      .btn-default:hover,
      .btn-default:focus,
      .open > .dropdown-toggle.btn-default:hover,
      .open > .dropdown-toggle.btn-default,
      .btn-default:active:hover,
      .btn-default.active:hover,
      .open > .dropdown-toggle.btn-default:hover,
      .btn-default:active:focus,
      .btn-default.active:focus,
      .open > .dropdown-toggle.btn-default:focus,
      .btn-default:active.focus,
      .btn-default.active.focus,
      .open > .dropdown-toggle.btn-default.focus {
        background: #fff;
        color: $app-blue;
        font-size: 16px;
      }
      .btn-group.open .dropdown-toggle {
        box-shadow: none;
      }
      .dropdown-menu {
        border-radius: $border-radius;
        font-size: 16px;
        .popover {
          top: 25px !important;
          .arrow {
            left: 25% !important;
          }
        }
        &:before {
          position: absolute;
          top: -7px;
          left: 20px;
          display: inline-block;
          border-right: 7px solid transparent;
          border-bottom: 7px solid $app-grey-light-2-color;
          border-left: 7px solid transparent;
          border-bottom-color: rgba(0, 0, 0, 0.2);
          content: '';
        }

        &:after {
          position: absolute;
          top: -6px;
          left: 21px;
          display: inline-block;
          border-right: 6px solid transparent;
          border-bottom: 6px solid #ffffff;
          border-left: 6px solid transparent;
          content: '';
        }
        .new-feature-badge {
          padding: 2px 4px;
        }
      }
      a > div {
        display: flex;
        align-items: center;
      }
      li > .active {
        color: $app-white;
        background: $app-blue;
        .new-feature-badge {
          background: $app-white;
          color: $app-blue;
        }
      }
    }
  }
}
