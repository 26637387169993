@import '../../../core/styles/_variables.scss';
@import '../../../core/styles/_mixins.scss';

.refer-a-friend {
  padding: 70px;
  @media screen and (max-width: 900px) {
    padding: 30px;
  }
  @media screen and (max-width: $mobile-resolution-max-width) {
    padding: 0px;
  }
  &__desc-container {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: $mobile-resolution-max-width) {
      padding: 20px;
      flex-direction: column-reverse;
    }
    &__text {
      width: 40%;
      @media screen and (max-width: 900px) {
        width: 45%;
      }
      @media screen and (max-width: $mobile-resolution-max-width) {
        width: 100%;
        margin-top: 50px;
      }
      &-title {
        @include h2;
      }
      &-desc {
        @include p;
        margin: 30px 0px;
      }
      &-steps {
        @include p;

        > div {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 10px;
        }
      }
      &-disclaimer {
        margin-top: 45px;
        @include label;
        color: $app-grey1;
        ol {
          padding-left: 17px;
        }
      }
    }
    &__arrow {
      width: 10%;
      background-image: url('../styles/images/arrow.png');
      background-position: center;
      background-size: 50px 160px;
      background-repeat: no-repeat;
      min-height: 200px;
      @media screen and (max-width: $mobile-resolution-max-width) {
        display: none;
      }
    }
    &__actions {
      width: 40%;
      @media screen and (max-width: $mobile-resolution-max-width) {
        width: 100%;
      }
      &-title {
        @include h2;
      }
      &-separator {
        display: flex;
        flex-direction: row;
        color: $app-grey1;
        &:before,
        &:after {
          content: '';
          flex: 1 1;
          border-bottom: $default-border;
        }
        &:before {
          margin: auto 10px auto auto;
        }
        &:after {
          margin: auto auto auto 10px;
        }
      }
      &-invite,
      &-link {
        height: 75px;
        margin: 30px 0px;
        @include p;
        button {
          height: 40px;
        }
        .form-control {
          height: 40px;
          border: $default-border;
          box-shadow: none;
          &:focus {
            border-color: $app-grey2;
            border-right-color: transparent;
            box-shadow: none;
            outline: none;
          }
          &:read-only {
            background-color: $app-white;
          }
        }
        button {
          display: flex;
          align-items: center;
        }
        i {
          color: $app-grey1;
          margin-right: 5px;
        }
        &__email-error {
          @include error-label-styles;
          margin: 0px;
        }
      }
      &-link {
        .form-control {
          @media screen and (min-width: $mobile-resolution-max-width) and (max-width: 1155px) {
            direction: rtl;
            text-overflow: ellipsis;
          }
          @media screen and(max-width: 475px) {
            direction: rtl;
            text-overflow: ellipsis;
          }
        }
      }
      &-social {
        display: flex;
        justify-content: space-between;
        button {
          width: 30%;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $app-white;
          padding: 0px;
          &:hover {
            color: $app-white;
          }
          @media screen and (max-width: 1000px) {
            width: 33%;
          }
          &.fb {
            background: #3b5998;
          }
          &.twitter {
            background: #1da2f2;
          }
          &.gmail {
            color: $app-black;
          }
        }
        .fb__logo,
        .twitter__logo,
        .gmail__logo {
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          width: 20px;
          height: 20px;
          margin-right: 7px;
        }
        .fb__logo {
          background-image: url('../styles/images/fb.png');
        }
        .twitter__logo {
          background-image: url('../styles/images/twitter.png');
        }
        .gmail__logo {
          background-image: url('../styles/images/gmail.png');
        }
        .fb__text,
        .twitter__text,
        .gmail__text {
          @media screen and (min-width: $mobile-resolution-max-width) and (max-width: 950px) {
            display: none;
          }
          @media screen and (max-width: 350px) {
            display: none;
          }
        }
      }
    }
  }
  &__step-outer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border: $default-border;
    border-color: $app-blue;
    border-radius: 50%;
    margin-right: 10px;
  }
  &__step-inner {
    line-height: normal;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $app-blue;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    color: $app-white;
  }
  &__step-text {
    width: calc(100% - 40px);
  }
  &__users {
    margin-top: 100px;
    &-title {
      @include h2;
      margin-bottom: 20px;
      @media screen and (max-width: $mobile-resolution-max-width) {
        padding-left: 20px;
      }
    }
    &-table {
      border: $default-border;
      border-radius: $border-radius;
      @media screen and (max-width: $mobile-resolution-max-width) {
        border: none;
        border-radius: 0px;
      }
    }
  }
  &__loader,
  &__no-data {
    min-height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $app-grey1;
    .mini-loader {
      position: relative;
      margin: 0px;
    }
  }
}
