@import '../../../core/styles/_mixins.scss';

.login-wrapper {
  display: flex;
  justify-content: center;
  background-color: $app-grey-bright-color;
  max-height: 520px;
  @media screen and (max-width: $mobile-resolution-max-width) {
    height: 100%;
  }

  .login-form {
    max-width: 480px;
    background: $app-white-color;
    border: $default-border;
    border-radius: 4px;
    width: 100%;
    @media screen and (max-width: $mobile-resolution-max-width) {
      margin-top: 65px;
    }
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 50px;
    }

    &__footer {
      padding: 10px 30px;
      background-color: hsla(0, 0%, 92.9%, 0.4);
      border-top: $default-border;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $app-grey1;
      span {
        margin-right: 5px;
      }
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      min-height: 380px;
      width: 100%;

      .error {
        @include error-label-styles;
      }
      @include error-input-styles;
    }
    &__title {
      text-align: center;
      width: 100%;
      margin: 0;
      font-size: 24px;
      font-weight: $font-weight-medium;
      padding-top: 30px;
    }
    &__forgot-password {
      position: relative;
      align-self: flex-end;
      right: 0;
      bottom: 10px;
      button {
        font-size: 12px;
      }
    }
    &__body {
      display: flex;
      flex: 1;
      flex-direction: column;
      width: 100%;
      padding: 0px 30px;
    }
    &__submit-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__sign-in {
      align-self: flex-start;
      width: 100%;
      margin-bottom: 25px;
      .mini-loader {
        position: static;
        height: 18px;
        width: 18px;
        margin: -2px 16px 0;
      }
    }
    &__social-login {
      display: flex;
      > div {
        display: flex;
        align-items: center;
      }
      span {
        color: $app-indigo-light-color;
      }
      img {
        height: 15px;
      }
      button {
        width: 36px;
        padding: 5px;
        margin-left: 10px;
      }
    }
    &__try-app {
      display: flex;
      align-items: center;
      margin-top: auto;
      justify-content: space-between;
      &__loader {
        height: 20px;
      }
      &__img {
        width: 15px;
        margin-left: 2px;
        height: 14px;
        margin-top: 6px;
      }
      > span {
        max-width: 200px;
        color: $app-indigo-light-color;
      }
      > div {
        display: flex;
        margin-left: 10px;
      }
      .mini-loader {
        height: 20px;
        width: 20px;
        margin: 2px 0 0 6px;
      }
    }
    &__error {
      margin-bottom: 15px;
      padding: 8px 10px;
      border-radius: 0;
      border: 0;
      border-top: 2px solid $app-red-color;
      color: $app-red-color;
      background-color: $app-red-light-color;
      font-size: 12px;
    }
    .re-captcha {
      margin-bottom: 10px;
      margin-top: -5px;
    }
    .btn-link {
      font-weight: $font-weight-medium;
    }
    a:hover {
      text-decoration: none;
      cursor: pointer;
    }
  }
}
