@import '../../../core/styles/variables.scss';

.profile-box-container {
  button#user-menu {
    margin: 0px;
    padding: 0px;
    border: none;
    width: auto;
    height: auto;
    background: transparent;
    display: flex;
    align-items: center;
    @media (max-width: $desktop-resolution-max-width + 30) {
      margin-right: 15px;
    }
  }
  .btn-default:active,
  .btn-default.active,
  .open > .dropdown-toggle.btn-default,
  .btn-default:active:hover,
  .btn-default.active:hover,
  .open > .dropdown-toggle.btn-default:hover,
  .btn-default:active:focus,
  .btn-default.active:focus,
  .open > .dropdown-toggle.btn-default:focus,
  .btn-default:active.focus,
  .btn-default.active.focus,
  .open > .dropdown-toggle.btn-default.focus,
  .btn-group > .btn:hover,
  .btn-group-vertical > .btn:hover,
  .btn-group > .btn:focus,
  .btn-group-vertical > .btn:focus,
  .btn-group > .btn:active,
  .btn-group-vertical > .btn:active,
  .btn-group > .btn.active,
  .btn-group-vertical > .btn.active {
    background: transparent;
    box-shadow: none;
  }

  ul.dropdown-menu.dropdown-menu-right {
    background: $app-white;
    border: $default-border;
    border-radius: $border-radius;
    min-width: 230px;
    padding: 0px;
    margin-top: 10px;
    @media (max-width: $desktop-resolution-max-width + 30) {
      margin-right: 15px;
    }
    li {
      border-bottom: none;

      a {
        padding: 10px;
        div {
          display: flex;
          align-items: center;
          i {
            margin-right: 10px;
            color: $app-grey1;
          }
        }
        &:hover {
          color: $app-blue;
          i {
            color: $app-blue;
          }
        }
      }
    }
    &:before {
      position: absolute;
      top: -7px;
      left: 204px;
      display: inline-block;
      border-right: 7px solid transparent;
      border-bottom: 7px solid $app-grey2;
      border-left: 7px solid transparent;
      content: '';
    }

    &:after {
      position: absolute;
      top: -6px;
      left: 205px;
      display: inline-block;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #ffffff;
      border-left: 6px solid transparent;
      content: '';
    }
  }

  .picture {
    display: inline-block;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border: $default-border;
    &:hover {
      cursor: pointer;
    }
  }
}
