@import '../../../core/styles/_variables.scss';

.markets-modal {
  .modal-dialog {
    height: calc(100% - 10px);
    .modal-content {
      height: 100%;
    }
    @media (orientation: landscape) {
      min-height: calc(100% - 10px);
      height: auto;
      width: 80%;
    }
  }
  .icn-simple-remove-big-thin {
    position: absolute;
    top: 15px;
    right: 10px;
    font-size: 18px;
    font-weight: $font-weight-bold;
  }
  .markets {
    &__header {
      padding: 0;
    }
  }

  @media (max-width: $mobile-resolution-max-width) {
    .markets {
      height: 100%;
      &__table {
        height: calc(100% - 140px);
        &__body {
          max-height: calc(100% - 30px);
          height: 100%;
        }
      }
    }
    .modal-body {
      height: 100%;
    }
  }
}
