@import '../../../core/styles/_mixins.scss';

.user-info {
  display: flex;
  flex: 1;
  flex-direction: column;

  .user-settings-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 80%;
    height: 100%;
    padding: 20px;
    color: $app-indigo-dark-color;
    @media (max-width: 950px) {
      width: 100%;
    }
    form {
      width: 100%;
    }
    &__loader {
      left: calc(50% - 18px);
      top: 50px;
      margin: 0;
      position: relative;
    }
    &__title {
      font-size: 24px;
      font-weight: $font-weight-medium;
      margin-bottom: 10px;
      &:not(:first-of-type) {
        margin-top: 30px;
      }
    }
    &__profile-image {
      margin: 40px 0px 20px 0px;
      @media (max-width: $mobile-resolution-max-width) {
        width: 100%;
      }
      > div:last-child {
        margin: 10px 0px;
      }
      .user-avatar {
        width: 65px;
        height: 65px;
      }
      .short-user-details__name {
        width: calc(100% - 75px);
      }
    }
    &__fields {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      margin-top: 20px;
      @media (max-width: 420px) {
        flex-direction: column;
        margin-top: 0px;
      }
      > div {
        width: 49%;
        @media (max-width: 420px) {
          width: 100%;
          margin-top: 20px;
        }
        input {
          width: 100%;
          height: 40px;
          border-radius: $border-radius;
          border: $default-border;
          padding-left: 10px;
          font-size: 14px;
        }
        input[type='text']:disabled {
          color: $app-indigo-light-color;
        }
        .language-select {
          height: 36px;
          &__control,
          &__control--is-focused {
            height: 40px;
            min-height: 40px;
            border: $default-border;
            box-shadow: none;
            border-radius: $border-radius;
          }
          &__single-value,
          &__option {
            > div {
              display: flex;
              align-items: center;
            }
          }
          .country-flag {
            height: 18px;
            margin-right: 10px;
          }
        }
        .contact-us {
          font-size: 12px;
          text-align: right;
          &__button {
            color: $app-blue;
            border: none;
            min-height: fit-content;
            font-weight: inherit;
            line-height: inherit;
            font-size: inherit;
            vertical-align: inherit;
            margin-left: 5px;
            &:hover {
              color: $app-blue;
            }
          }
        }
        @include error-input-styles;
        .error {
          @include error-label-styles;
        }
      }

      &__label-box {
        display: flex;
        justify-content: space-between;
      }
      &:first-of-type {
        align-items: flex-end;
        margin-top: 10px;
      }
    }
    &__buttons {
      margin-top: 40px;
    }
    &__upload-new-photo {
      .mini-loader {
        position: static;
        height: 20px;
        width: 20px;
        margin: 0 20px 0;
      }
    }
    .phone-verification-input {
      button {
        margin-right: 10px;
        margin-bottom: 10px;
      }
      .input-validation-box {
        position: absolute;
        display: flex;
        align-items: center;
        right: 10px;
        top: 10px;
        font-size: 12px;
        width: 40%;
        justify-content: flex-end;
        img {
          height: 16px;
          margin-left: 8px;
        }
        &.verified {
          color: $app-green1;
        }
        &.not-verified {
          color: $app-red;
        }
      }
      .verify-phone-button {
        position: relative;
        padding-left: 25px;
        padding-right: 25px;
        .mini-loader {
          height: 14px;
          width: 14px;
          right: 7px;
          top: 14px;
        }
      }
    }
    &__fee {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 49%;
      margin-bottom: 20px;
      &__discount {
        color: $app-grey1;
      }
      .slider {
        min-width: 40px;
      }
      @media (max-width: 420px) {
        width: 100%;
      }
    }
    .user-settings-trading-info {
      margin-top: 10px;
    }
    @media (max-width: $mobile-resolution-max-width) {
      &__title {
        font-size: 18px;
        font-weight: $font-weight-bold;
      }
    }
  }
  &__trading {
    padding: 20px;
  }
}
