@import '../../../core/styles/_mixins.scss';

.withdraw-modal {
  .modal-content {
    max-width: 420px;
    min-width: 320px;
    width: 100%;
  }
  .modal-dialog {
    max-width: 420px;
    min-width: 320px;
    margin: 0px auto;
    width: 100%;
  }
  .modal-header {
    border-bottom: none;
    display: flex;
    justify-content: space-between;
    &::before {
      content: none;
    }
    &::after {
      content: none;
    }
    i {
      font-size: 18px;
    }
    > div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      span {
        font-size: 16px;
        font-weight: $font-weight-medium;
      }
    }
  }
  &__amount {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 30px;
    border-bottom: $default-border;
    span:not(:last-child) {
      margin-right: 4px;
    }
  }
  &__label {
    margin-top: 20px;
    margin-bottom: 5px;
  }
  &__input {
    position: relative;
    height: 60px;
    > div {
      top: 8px;
      right: 25%;
      margin-right: 8px;
      position: absolute;
      display: flex;
      align-items: center;
      div:last-child {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: $app-grey2;
        img {
          width: 24px;
          height: 24px;
        }
      }
      div:first-child {
        margin-right: 5px;
        color: $app-grey1;
      }
    }
    input {
      width: 75%;
      border-radius: $border-radius;
      border: $default-border;
      padding: 8px 80px 8px 8px;
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        appearance: none;
      }
      &.error-input-display {
        @include error-input-display;
      }
      &.mfa-input {
        width: 100%;
        padding: 8px;
      }
    }
    span {
      @include error-label-styles;
    }
    button {
      width: calc(25% - 10px);
      margin-left: 10px;
      padding: 0;
      @media screen and (max-width: $mobile-resolution-max-width) {
        top: 6px;
      }
    }
  }
  &__select {
    .error-input-display {
      .account-select__control {
        @include error-input-display;
      }
    }
    .account-select {
      &__control,
      &__control--is-focused {
        height: 36px;
        min-height: 36px;
        border: $default-border;
        box-shadow: none;
        border-radius: $border-radius;
        &:hover {
          border: $default-border;
        }
      }
    }
    .error-label {
      @include error-label-styles;
    }
  }
  &__button {
    margin-top: 10px;
    button {
      width: 100%;
    }
  }
}
