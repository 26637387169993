@import '../../../core/styles/_variables.scss';

.market-depth {
  display: flex;
  &__chart {
    height: 440px;
    width: 100%;
    padding: 10px;
  }
}
