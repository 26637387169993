@import '../../../core/styles/_variables.scss';

.enter-value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  outline: none;
  &__text {
    width: 30%;
    font-size: 12px;
  }
  &__input {
    width: 70%;
    text-align: right;
    &-at-price {
      color: $app-grey1;
      font-size: 12px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    &__container {
      position: relative;
      &-currency {
        position: absolute;
        right: 10px;
        top: 7px;
        color: $app-grey1;
        font-size: 12px;
      }
      input {
        width: 100%;
        border: $default-border;
        border-radius: $border-radius;
        height: 30px;
        font-size: 12px;
        padding-left: 10px;
        padding-right: 50px;
        color: $app-indigo-dark-color;
      }
    }
  }
}

.enter-value-validation {
  font-size: 13px;
  color: $app-red-color;
}
