@import '../../../../core/styles/_variables.scss';

.trade-history {
  height: 426px;
  overflow: hidden;
  overflow-y: auto;
  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: calc(50% - 40px);
    position: relative;
    .mini-loader {
      position: relative;
      margin: 0px;
    }
  }
  &__no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: calc(50% - 35px);
    color: $app-grey1;
  }
}
