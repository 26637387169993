@import '../../../core/styles/_variables.scss';

.open-order-container {
  &__navigation {
    background-color: $app-grey-bright-color;
    padding-bottom: 8px;
    &__body {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 48px;
      padding: 0 10px;
      border-bottom: $default-border;
      background-color: $app-white;
      .btn {
        .icn {
          position: relative;
          top: 2px;
          color: $app-blue;
        }
        .icn-minimal-left-big-thin {
          font-weight: $font-weight-bold;
          right: 3px;
        }
        .icn-tablet-reader {
          right: 6px;
        }
        &:last-of-type {
          color: $app-black;
        }
      }
    }
  }
  &__buy-sell-toggle {
    height: 40px;
    width: 100%;
    border-top: $default-border;
    margin-left: 0;
    .btn-group {
      display: flex;
      height: 100%;
      width: 100%;
      margin-left: 0;
      .btn {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        border: none;
        border-radius: 0;
        border-bottom: $default-border;
        background-color: $app-grey-bright-color;
        color: $app-indigo-light-color;
        font-size: 16px;
        &.active {
          box-shadow: none;
          border-bottom: none;
          background-color: $app-white-color;
          font-size: 18px;
          font-weight: $font-weight-medium;
          &:first-child {
            border-right: $default-border;
          }
          &:last-child {
            border-left: $default-border;
          }
        }
        &:first-of-type {
          &.active {
            color: $app-green-text-color;
          }
        }
        &:last-of-type {
          &.active {
            color: $app-red-color;
          }
        }
      }
    }
  }
  @media (max-width: $mobile-resolution-max-width) {
    &.guest {
      margin-bottom: 25px;
      &.iphone-x {
        margin-bottom: 55px;
      }
    }
  }
}
