@import '../../../core/styles/_variables.scss';

.settings-mfa {
  display: flex;
  flex-direction: column;
  &__title {
    font-size: 24px;
  }
  &__subtitle {
    font-size: 20px;
    margin-top: 30px;
  }
  &__description {
    color: $app-grey1;
    margin: 5px 0;
  }
  &__actions {
    margin-top: 10px;
    .btn {
      margin-top: 10px;
    }
  }
  &__disable-btn {
    &.btn-default,
    &.btn-default:hover,
    &.btn-default:hover:active,
    &.btn-default:active,
    &.btn-default:active:focus,
    &.btn-default:focus {
      color: $app-blue;
      border: 1px solid $app-blue;
      font-weight: $font-weight-bold;
      margin-right: 10px;
    }
  }
}
