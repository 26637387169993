@import '../../../core/styles/_variables.scss';

.orders {
  height: 100%;
  &__empty {
    padding: 40px 0px;
    width: 100%;
    height: calc(100% - 60px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
      width: 110px;
      height: 110px;
      margin-bottom: 15px;
    }
    div {
      margin: 10px 0;
    }
  }
  &__navigation {
    height: 60px;
    border-bottom: $default-border;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
    @media screen and (max-width: $mobile-resolution-max-width) {
      .main-navigation__items {
        padding-left: 3px;
      }
    }
  }
  .orders_loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.show-all-orders {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px 0px;
}
