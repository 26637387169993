// Colors
$app-green-color: #3dc752;
$app-green-light-color: #e7f5eb;
$app-red-color: #ee3142;
$app-red-light-color: #fdeaec;
$app-red-dark-color: #931520;
$app-blue-color: #1585d8;
$app-orange-color: #ff9908;
$app-orange-light-color: #f5a623;
$app-orange-light-2-color: #f3f1e8;
$app-white-color: #ffffff;
$app-black-color: #000000;
$app-indigo-dark-color: #1d1e37;
$app-indigo-light-color: #7f95b8;
$app-grey-dark-color: #778088;
$app-grey-light-color: #c4cad3;
$app-grey-light-2-color: #e4e9ef;
$app-grey-bright-color: #f3f7fa;

// Body text, headlines, backgrounds
$app-black: #1d1e37;
$app-black-hover: #33344b;
$app-black-active: #020305;
// Description text and backgrounds
$app-grey1: #7f93bc;
$app-grey1-hover: #8b9dc2;
$app-grey1-active: #7284a9;
// Light backgrounds to separate sections
$app-grey2: #e4e9ef;
$app-grey2-hover: #eceff3;
$app-grey2-active: #cdd1d7;
// Light backgrounds to separate sections
$app-grey3: #f8f9fb;
// Overall CTA buttons and text links (not for Buy & Sell)
$app-blue: #3179ff;
$app-blue-hover: #4586ff;
$app-blue-active: #2c6ce5;
// Shapes that represents Buy/Bid. Used for shapes only
$app-green1: #3dc752;
$app-green1-hover: #50cc63;
$app-green1-active: #36b349;
// Shapes that represents Buy/Bid. Used for text only
$app-green2: #21a936;
$app-green2-hover: #37b54a;
$app-green2-active: #1d9b30;
// Shapes that represents Sell/Bid. Used for text and shapes
$app-red: #ee3142;
$app-red-hover: #ef4553;
$app-red-active: #d62c39;
// Warning buttons
$app-orange1: $app-orange-color;
$app-orange1-hover: lighten($app-orange1, 5%);
$app-orange1-active: darken($app-orange1, 5%);
// White buttons and elements
$app-white: #ffffff;
$app-white-hover: #fcfcfc;
$app-white-active: #f8f9fb;
$app-white-border: #e4e9ef;
// Shapes that represents warning message
$app-orange: #ffbf3d;

$error-background-color: #fbeae5;

$app-green-text-color: #21ad36;

// Border styles
$default-border: 1px solid $app-grey2;
$border-radius: 3px;

// Font styles
$main-font-family: 'Inter UI', sans-serif;
$app-font-size-large: 18px;
$validation-error-font-size: 10px;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-black: 900;

// Element sizes
$app-main-navigation-height: 60px;

// Screen sizes
$mobile-resolution-max-width: 768px;
$desktop-resolution-max-width: 1280px;

// Margins / Paddings
$modal-dialog-margin: 0.5rem;
