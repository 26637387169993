@import '../../../core/styles/_mixins.scss';

.deposit-modal {
  .modal-content {
    max-width: 620px;
    min-width: 320px;
    width: 100%;
  }
  .modal-dialog {
    max-width: 620px;
    min-width: 320px;
    margin: 0px auto;
    width: 100%;
  }
  .modal-header {
    border-bottom: none;
    display: flex;
    justify-content: space-between;
    &::before {
      content: none;
    }
    &::after {
      content: none;
    }
    i {
      font-size: 18px;
    }
    > div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      span {
        font-size: 18px;
        font-weight: $font-weight-medium;
      }
    }
  }
  .modal-body {
    padding: 0px;
  }
  &__primary {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    &__address {
      display: flex;
      align-items: center;
      min-height: 36px;
      input {
        width: 100%;
        max-width: 400px;
        border: $default-border;
        border-radius: $border-radius;
        padding: 7px 10px;
        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        @media (max-width: $mobile-resolution-max-width) {
          &[type='text'] {
            font-size: 14px;
          }
        }
      }
      .btn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    &.with-tag {
      @media (min-width: 500px) {
        flex-wrap: initial;
        background: $app-grey-bright-color;
        border-top: $default-border;
        border-bottom: $default-border;
        padding: 5px 15px;
        .deposit-modal__address {
          padding: 10px 0;
        }
        .deposit-modal__qr-code {
          width: auto;
          border: none;
        }
      }
    }
    > div:last-of-type {
      padding: 0 15px;
    }
    @media (max-width: 440px) {
      flex-direction: column;
      align-items: initial;
    }
  }
  &__qr-code {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 20px 0;
    background: $app-grey-bright-color;
    border-top: $default-border;
    border-bottom: $default-border;
    > div {
      display: flex;
      padding: 4px;
      background: $app-white;
    }
  }
  &__address {
    flex: 1;
    padding: 25px 0;
  }
  &__additional {
    padding: 20px 15px 15px;
    &__code {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 10px;
      border-bottom: $default-border;
      > div:first-of-type {
        margin-right: 15px;
        margin-bottom: 15px;
      }
      > div:last-of-type {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
        color: #d58213;
        > div:first-of-type {
          font-weight: $font-weight-bold;
        }
      }
    }
    > div:last-of-type {
      margin-top: 15px;
    }
    .deposit-modal__text:first-of-type {
      font-weight: $font-weight-medium;
      font-size: 14px;
    }
  }
  &__text {
    color: $app-grey1;
    font-size: 12px;
    > div {
      margin-top: 15px;
    }
  }
  &__action-button {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    padding: 15px;
  }
}
