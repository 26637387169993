@import '../../../../core/styles/_variables.scss';

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
  margin-bottom: 0px;
  &--disabled {
    opacity: 0.7;
  }
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $app-grey2;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $app-blue;
}

input:focus + .slider {
  box-shadow: 0 0 1px $app-blue;
}

input:checked + .slider:before {
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 30px;
}

.slider.round:before {
  border-radius: 50%;
}
