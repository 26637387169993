@import '_variables.scss';

//== Inputs
@mixin input-default-border {
  border: $default-border;
  border-radius: $border-radius;
}

//== Panels
@mixin panel-default-border {
  border: $default-border;
  border-radius: $border-radius;
}

//== Validation
@mixin error-input-display {
  background: $error-background-color;
  border: 1px solid $app-red-color;
}
@mixin error-input-styles {
  .input-error,
  .input-error:hover,
  .input-error:focus {
    @include error-input-display;
  }
}
@mixin error-label-styles {
  color: $app-red-color;
  font-size: $validation-error-font-size;
  line-height: 1;
  letter-spacing: 0.2;
  margin: 2px 0 0 3px;
}

//== Buttons
@mixin button-disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: default;
}

@mixin h1 {
  font-size: 30px;
  line-height: 40px;
}
@mixin h2 {
  font-size: 24px;
  line-height: 32px;
}
@mixin h3 {
  font-size: 20px;
  line-height: 28px;
}
@mixin h4 {
  font-size: 18px;
  line-height: 24px;
}
@mixin p {
  font-size: 16px;
  line-height: 24px;
}
@mixin label {
  font-size: 14px;
  line-height: 20px;
}
@mixin small-text {
  font-size: 12px;
  line-height: 16px;
}
@mixin small-text-capitalized {
  font-size: 11px;
  line-height: 16px;
}
