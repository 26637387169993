@import '../../../core/styles/_variables.scss';

.user-status-box {
  width: calc(100% - 30px);
  margin: 15px 15px 0px 15px;
  display: flex;
  align-items: center;
  padding: 15px;
  max-width: $desktop-resolution-max-width;
  flex: 0 0 auto;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  border: $default-border;
  @media (max-width: $mobile-resolution-max-width) {
    width: 100%;
    margin: 15px 0px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  &.blue-with-background {
    background-color: $app-white-color;
    background-image: url('../images/upgrade.png');
    background-size: cover;
    background-position: 0 0;
    background-repeat: no-repeat;
    .user-status-box__desc {
      padding-left: 50px;
      @media screen and(max-width: $mobile-resolution-max-width) {
        padding-left: 0px;
      }
    }
  }
  &.blue {
    background: $app-white-color;
    border-top: 3px solid $app-blue;
  }
  &.orange {
    background: $app-orange-light-2-color;
    border-top: 3px solid $app-orange-light-color;
    .user-status-box__buttons {
      .btn-link {
        color: $app-orange1;
        &:hover {
          color: $app-orange1-active;
        }
      }
    }
  }
  &__desc {
    width: 70%;
    @media (max-width: 890px) {
      width: 60%;
    }
    @media (max-width: $mobile-resolution-max-width) {
      width: 100%;
    }
    &-title {
      font-size: 16px;
      font-weight: $font-weight-medium;
    }
    &-text {
      color: $app-indigo-light-color;
    }
  }
  &__buttons {
    width: 30%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media (max-width: 890px) {
      width: 40%;
    }
    @media (max-width: $mobile-resolution-max-width) {
      width: 100%;
      margin-top: 20px;
    }
    button {
      &:not(.btn-link) {
        margin-right: 10px;
      }
      .mini-loader {
        position: static;
        height: 18px;
        width: 18px;
        margin: -2px 16px 0;
      }
    }
  }
  .blue-text {
    color: $app-blue;
  }
}

.account-upgrade-notice {
  &.prompt {
    .modal-content {
      .modal-body {
        .prompt__title {
          padding: 0;
        }
        .prompt__content {
          padding: 25px 0 0;
          text-align: left;
          &__message {
            .account-upgrade-notice__warning {
              margin-top: 20px;
              .icn-alert {
                color: $app-orange;
                position: relative;
                top: 2px;
              }
            }
          }
          button {
            display: flex;
            margin: 20px auto 10px;
          }
        }
      }
    }
  }
}
