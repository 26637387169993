@import '../../../core/styles/_variables.scss';

.change-password {
  display: flex;
  flex-direction: column;
  &__container {
    display: flex;
    margin-top: 20px;
    &__left {
      padding-right: 20px;
      border-right: $default-border;
    }
    &__right {
      display: flex;
      flex-direction: column;
      padding-left: 20px;
    }
    > div {
      width: 50%;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    @media (max-width: $mobile-resolution-max-width) {
      flex-direction: column-reverse;
      > div {
        width: 100%;
        padding: 0;
        border: 0;
        &:last-of-type {
          margin-bottom: 30px;
        }
      }
    }
  }
  &__title {
    font-size: 24px;
  }
  &__description {
    color: $app-grey1;
    margin: 5px 0;
  }
  &__criteria-title {
    font-size: 16px;
    font-weight: $font-weight-medium;
    margin-bottom: 10px;
  }
  &__criteria-item {
    color: $app-grey1;
    margin: 7px 0;
  }
  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
    .btn-link {
      margin-right: 20px;
    }
  }
}
