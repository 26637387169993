@import '../../../core/styles/_variables.scss';

.exchange-open-order {
  display: flex;
  justify-content: space-between;
  &__buy {
    width: calc(50% - 5px);
    background-color: #fff;
    border: $default-border;
    border-radius: $border-radius;
  }
  &__sell {
    width: calc(50% - 5px);
    background-color: #fff;
    border: $default-border;
    border-radius: $border-radius;
  }
}
