@import '../../../core/styles/_mixins.scss';
@import '../../../core/styles/_variables.scss';

.phone-verification-modal {
  .modal-dialog {
    margin: 0px auto;
  }
  .modal-header {
    border-bottom: none;
  }

  .modal-content {
    max-width: 420px;
    margin: 0px auto;
  }
  &__title {
    font-size: 20px;
    color: $app-indigo-dark-color;
    line-height: 32px;
  }
  &__text {
    font-size: 16px;
    color: $app-grey1;
    text-align: center;
    line-height: 24px;
    width: 70%;
    margin: 0px auto;
  }
  &__error {
    color: $app-red-color;
    font-size: 14px;
  }
  &__body {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;

    &__item {
      &__smartphone {
        margin: 0 8px;
        height: 70px;
        min-width: 50px;
        align-self: flex-start;
        img {
          height: 60px;
        }
      }
    }

    .phone-verification-box {
      display: flex;
      flex-direction: column;
      margin-top: 40px;
      margin-bottom: 30px;
      &__item {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        &__first {
          flex: 1;
          min-width: auto;
          &:last-of-type {
            input {
              margin-right: 0;
            }
          }
          input {
            font-size: 12px;
            width: 64px;
            height: 64px;
            margin-right: 10px;
            font-size: 36px;
            text-align: center;
          }
        }
        &__second {
          flex: 3;
          padding-left: 10px;
          p {
            margin-bottom: 0;
            color: #989ca3;
          }
          &__send-button {
            border: none;
            min-height: fit-content;
            font-weight: inherit;
            font-size: 12px;
            line-height: inherit;
            &:hover {
              color: $app-blue;
            }
          }
        }
        .phone-verified {
          .input-and-error {
            input {
              border: 1px solid $app-green-color;
            }
          }
        }
      }
    }
  }
  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px -15px 0 -15px;
    padding-top: 10px;
    button:first-child {
      margin-bottom: 10px;
    }
  }

  @include error-input-styles;

  @media screen and (max-width: $mobile-resolution-max-width) {
    .modal-dialog {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }
}
